import React, { useState, useEffect, useContext } from 'react';
import img from '../../assets/logo.jpg';
import imgbg from '../../assets/jcb.jpg';

// import greenIndia from "../../assets/greenIndia.svg"
// import facebook from '../../assets/facebook.png'
// import google from '../../assets/google.png'
import logo from '../../assets/newlogoss.png';
import './login.scss';

import { Link, useNavigate } from 'react-router-dom';
// import { useContext } from 'react'
import { EcomContext } from '../../context/context'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { login } from '../../api/login';

const SignIn = () => {
  const {loginHandler,userInfo,isLoggin} = useContext(EcomContext)
  const navigate = useNavigate();

  // const [error, setError] = useState('');
  // useEffect(() => {
  //   setEmail(localStorage.getItem('email') || '');
  //   setPassword(localStorage.getItem('password') || '');
  // }, []);
  const [password, setPassword] = useState('');
  const [telNum, setTelNum] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    setPassword(inputValue);
  };

  const handleInputNumber = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    setTelNum(inputValue);
  };

  const onSigIn = async (e) => {
    e.preventDefault();

    if (!telNum || !password) {
      toast.error('Please enter phone number and password');
      return;
    }

    const data = {
      mobile: telNum,
      password,
    };
  

    try {
      const responseLogin = await loginHandler(data);
      if(responseLogin.type==="error"){
        toast.error(responseLogin.message)
      }
      if (responseLogin.type === 'success') {
        setTelNum('');
        setPassword('');
        toast.success(responseLogin.message);
        navigate('/');
      }
    } catch (error) {
      toast.error('Email or Password is not correct');
    }
  };
  // const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div>
      {/* <div className='container-fluid loginContainer'>
        <div className='row login_container'>
          <div className='col-6'>
            <img src={img} class="img-fluid" className='food_img' alt="logo" />
          </div>
          <div className='col-6 p-5 formBackground'>
            <h1 className='text-center text-white'>Login</h1>
            <div className="mb-3">
              <label for="formGroupExampleInput" className="form-label text-white emailInput">Email</label>
              <input type="email" value={email} required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" onChange={(e) => setEmail(e.target.value)} className="form-control" id="formGroupExampleInput" placeholder="Enter Email" />
            </div>
            <div className="mb-3">
              <label for="formGroupExampleInput2" className="form-label text-white passwordInput">Password</label>
              <input type={showPassword ? "text" : "password"} value={password} required onChange={(e) => setPassword(e.target.value)} className="form-control" id="formGroupExampleInput2" placeholder="Enter Password" maxLength="12" />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="eye-icon"
                onClick={handleShowPassword}
              />
            </div>
            {error && <p className="text-white">{error}</p>}
            <p className='forgotPassword'>Forgot Password ?</p>
            <div className='signIn_btn'>
              <button type="button" class="btn btn-secondary w-100 text-black" onClick={onSigIn}>Sign In</button>
            </div>
            <div className='mt-5 d-flex justify-content-around align-item-center line_or'>
              <div className='line'></div>
              <div className='text-white or'>Or</div>
              <div className='line'></div>
            </div>
            <div className='mt-1 d-flex justify-content-around align-item-center fb_google'>
              <div className='signIn text-center mt-4 text-white'><h2>Sign in with </h2></div>

              <div className='facebook'>
                <img src={facebook} class="img-fluid" alt="logo" />
                <img src={google} class="img-fluid" alt="logo" />

              </div>
            </div>
            <div className='d-flex justify-content-around align-item-center p-5 new_user'>
              <div className='newUser'>
                <h4>New User ?</h4>
              </div>
              <div>
                <button type="button" class="btn btn-light w-100 h-100 p-3 newAccount" onClick={()=>navigate('/signup')}>Create New <br /> Account</button>
              </div>

            </div>
          </div>
        </div>
      </div> */}
      <div className="login-container">
        <div className="login-left">
          <a href="/" className="logo">
            <img src={logo} alt="logo" />
          </a>

          <img src={imgbg} alt="background" className="bg-img" />
        </div>
        <div className="login-right">
          <h2>Sign In</h2>
          <form onSubmit={onSigIn}>
            <input
              type="text"
              value={telNum}
              onChange={handleInputNumber}
              class="mobile-number__field"
              placeholder="Enter your mobile number"
              pattern="[0-9]{10}"
              maxLength={10}
            />

            <div className="password-containers_">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter Pin Number"
                value={password}
                onChange={handleInputChange}
                required
                pattern="[0-9]{4}"
                maxLength={4}
              />
              <span className="password-toggle">
                <FontAwesomeIcon
                  icon={!showPassword ? faEyeSlash : faEye}
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                />
              </span>
            </div>
            <button type="submit">Sign In</button>
          </form>
          <h5 className="mt-5">OR</h5>
          <p>
            Don't have an account? please click to register here{' '}
            <Link to="/signup">Sign up</Link>
          </p>
          {/* <p>
        <a href="#"  onClick={()=>navigate('/signup')} >Sign in</a>
      </p> */}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
