import React, { useContext, useEffect, useState } from "react";
import { EcomContext } from "../../context/context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IMG_URL } from "../../config";
import "./FilteredSearch.scss";
import Header from "../../components/Header/Header";
import SearchBar from "../../components/SearchBar/SearchBar";
import Footer from "../../components/Footer/Footer";
import Loader from "../Loader/Loader";
import grid from '../../assets/grid.svg';
import list from '../../assets/list.svg';
import { useTranslation } from 'react-i18next';


const FilteredSearch = () => {
  const { t } = useTranslation();
  const [view, setView] = useState('grid');
  const [toggle, setToggle] = useState('grid');
  const { myProducts } = useContext(EcomContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate=useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get("title");
  const filteredSearch = [];

  queryParams.forEach((value, key) => {
    if (key === "title") {
      filteredSearch.push({ title: decodeURIComponent(value) });
    }
  });
  const filteredProducts = myProducts.filter((item) => {
    const productTitle = item.title.toLowerCase().replace(/\s+/g, ' ').trim();
    const searchTitle = title.toLowerCase().replace(/\s+/g, ' ').trim();
    return productTitle.includes(searchTitle)
  });
  
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);
  const viewFullProduct = (id) => {
    navigate(`/productpage/${id}`);
  };
  const toggleGridList = () => {
    if (window.innerWidth > 700) {
      setView(view === 'grid' ? 'list' : 'grid');
      setToggle(!toggle);
    }
  };

  return (
    <div>
    <>
      <Header />
      <SearchBar />
    </>
    {isLoading && <Loader />}
    <div className="container">
      <div className="d-flex align-items-baseline justify-content-between">
        <p className="headerName ps-0">{t('Search Filtered Service List')}</p>
        {window.innerWidth > 768 && (
          <button
            onClick={toggleGridList}
            type="button"
            className="toggleBtn"
          >
            {toggle ? (
              <img src={grid} className="img-fluid gridIcon" alt="grid" />
            ) : (
              <img src={list} className="img-fluid listIcon" alt="grid" />
            )}
          </button>
        )}
      </div>
      {view === 'grid' ? (
        <div>
          <div className="product-grid">
            {filteredProducts.map((product) => (
              <div className="grid-card" key={product._id}>
                <div className="grid-img">
                  <img
                    src={`${IMG_URL}/${product.image}`}
                    onClick={() => {
                      setIsLoading(true);
                      setTimeout(() => {
                        setIsLoading(false);
                        viewFullProduct(product._id);
                      }, 300);
                    }}
                    alt="product1"
                  />
                </div>

                <div className="grid-card-div">
                  <h3>{t(product.title.substring(0, 15))}</h3>
                  <p className="grid-description">
                    {t(product.description.substring(0, 50))}
                  </p>
                  <p className="grid-amount">₹ {product.price}</p>
                  {/* <button onClick={() => addToCart(product)}>
                    Add to Cart
                  </button> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="items">
            {filteredProducts.map((product) => (
              <div className="product_card mt-3 mb-3 product_list_item">
                <div className="item" key={product._id}>
                  <div
                    className="item-img-name"
                    onClick={() => viewFullProduct(product._id)}
                  >
                    <div className="d-flex justify-content-center align-item-center">
                      <img
                        src={`${IMG_URL}/${product.image}`}
                        alt="product1"
                      />
                      <div className="product_container">
                        <h2 className="ps-3">{product.title}</h2>
                        <p className="product_description ms-3 mb-3">
                          {product.description}
                        </p>
                        <div className="view ms-3 m-0">
                          <a
                            href={`/productpage/${product._id}`}
                            onClick={() => viewFullProduct(product._id)}
                          >
                            View details
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pe-3">
                    <h3 className="pb-1 ps-5">₹ {product.price}</h3>
                    {/* <button
                      className="btn btn-primary"
                      onClick={() => addToCart(product)}
                    >
                      Add to Cart
                    </button> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
    <Footer />
  </div>
  );
};

export default FilteredSearch;
