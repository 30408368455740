import React from 'react'
import Header from '../../components/Header/Header'
import SearchBar from '../../components/SearchBar/SearchBar'
import Footer from '../../components/Footer/Footer'
import "./AboutUs.scss"
import { useTranslation } from 'react-i18next';

function AboutUs() {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <Header />
        <SearchBar />
      </div>
      <div className='header'>
        <h3>{t('About Us')}</h3>
        <p>ನಡೆದುಬಂದ ದಾರಿ</p>
      </div>
      <div className='container description'>
        <p>
        
        2015 ನಬಾರ್ಡನ ಆರ್ಥಿಕ ಸಹಕಾರದಲ್ಲಿ 10 ಜನ ರೈತ ಸದಸ್ಯರ ಒಗ್ಗೂಡಿಸಿ (ಪ್ರವರ್ತಕರು)
        ಶ್ರೀ. ಹರಿಶ್ಚಂದ್ರ ಭಟ್ಟ ಮತ್ತು ಶ್ರೀ. ವಿವೇಕ ಹೆಗಡೆ ಇವರ ಮುಂದಾಳತ್ವದಲ್ಲಿ “ಪ್ರಗತಿಮಿತ್ರ ನಾರ್ಥ ಕೆನರಾ
        ಫಾಮರ‍್ಸ್ ಪ್ರೊಡ್ಯುಸರ್ ಕಂಪನಿ ಲಿಮಿಟೆಡ್” ಸ್ಥಾಪನೆಯಾಯಿತು. ಶಿರಸಿಯಲ್ಲಿ ಕೇಂದ್ರ ಕಛೇರಿಯನ್ನು ಸ್ಥಾಪಿಸಿ, ಶ್ರೀ. ಅನಂತ ಶಿವರಾಮ ಹೆಗಡೆ ಇವರ ಸ್ಥಳದಲ್ಲಿ
        ಸಾಮೂಹಿಕ ಸಂಸ್ಕರಣಾ ಕೇಂದ್ರವನ್ನು ತೆರೆಯಲಾಯಿತು. ಸರಿ ಸುಮಾರು 1058 ಕ್ಕೂ ಹೆಚ್ಚು ರೈತ ಸದಸ್ಯರ 
        ಪಾಲುದಾರಿಕೆಯನ್ನು ಸಾಧಿಸಿ ನಮ್ಮ ಸಂಸ್ಥೆ 7ನೇ ವರ್ಷಕ್ಕೆ ಕಾಲಿಟ್ಟಿದೆ.

        </p>
      </div>
      <div className='container description'>
      <div className='how_we_work'>
        <h3>ಕಂಪನಿಯ ಚಟುವಟಿಕೆಗಳು ಮತ್ತು ಅದರಿಂದ ಆಗುತ್ತಿರುವ ಪ್ರಯೋಜನಗಳು?</h3>
      </div>
        <p>
          ಉತ್ತರ ಕನ್ನಡ ಜಿಲ್ಲೆ ಹೊರನೋಟಕ್ಕೆ ಸಮೃದ್ಧವಾಗಿ ಕಂಡರೂ ಕೃಷಿಕ ತಾನು ಮಾಡುವ ಕೃಷಿ
          ಚಟುವಟಿಕೆಯಲ್ಲಿ ಅನೇಕ ಸವಾಲುಗಳನ್ನು ಎದುರಿಸುತ್ತಿದ್ದಾನೆ ಎಂದು ಅರಿತು ನಮ್ಮ ಜಿಲ್ಲೆಯ ಮಲೆನಾಡು
          ಭಾಗಗಳಾದ ಶಿರಸಿ, ಸಿದ್ಧಾಪುರ ಮತ್ತು ಯಲ್ಲಾಪುರ ತಾಲೂಕಿನ ಅಡಿಕೆ, ಕಾಳುಮೆಣಸು, ತೆಂಗು ಮತ್ತು
          ಭತ್ತದ ಬೆಳೆಗಾರರ ಜ್ವಲಂತ ಸಮಸ್ಯೆಗಳಿಗೆ ಸುಸ್ಥಿರ ಮತ್ತು ದೀರ್ಘಕಾಲದ ಪರಿಹಾರವನ್ನು ಕಂಡುಕೊಳ್ಳುವ
          ನಿಟ್ಟಿನಲ್ಲಿ ರಚಿತವಾದ ರೈತ ಸಮೂಹ ಸಂಸ್ಥೆ   “ಪ್ರಗತಿಮಿತ್ರ ನಾರ್ಥ ಕೆನರಾ ಫಾಮರ‍್ಸ್ ಪ್ರೊಡ್ಯುಸರ್ ಕಂಪನಿ
          ಲಿಮಿಟೆಡ್”.
        </p>
        <p>
            ಅವುಗಳಲ್ಲಿ ಹೆಚ್ಚಿನ ರೈತರು (85% ಹೆಚ್ಚು) ಸಣ್ಣ ಮತ್ತು ಅತೀ ಸಣ್ಣ ಹಿಡುವಳಿದಾರರಾಗಿದ್ದು, ನಮ್ಮ ಯುವ
            ಪೀಳಿಗೆಯು ಉದ್ಯೋಗ ಅರಸಿ ಪಟ್ಟಣಗಳಿಗೆ ವಲಸೆ ಹೋಗುತ್ತಿರುವ ಕಾರಣ ಅನಿವಾರ್ಯತೆಯಿಂದ
            ವಯೋವೃದ್ಧರು ಕೃಷಿ ಚಟುವಟಿಕೆಗಳನ್ನು ನಡೆಸುವಂತಹ ಪರಿಸ್ಥಿತಿ ಬಂದೊದಗಿದೆ.
            ಮತ್ತೊAದೆಡೆ ಕೃಷಿ ಚಟುವಟಿಕೆ ಮಾಡಬಲ್ಲವರಿಗೆ (ಆಳು) ಭೂಮಿಯೇ ಇಲ್ಲದಿರುವುದು,
            ಹೀಗಾಗಿ ವರ್ಷ ಪೂರ್ತಿ ಕೃಷಿ ಕೆಲಸವಿಲ್ಲದೆಯೇ ಕೃಷಿ ಕೆಲಸ ಬಲ್ಲವರು ತಮ್ಮ ಜೀವನ ನಿರ್ವಹಣೆಗೆಂದು
            ಕೃಷಿಯೇತರ ಚಟುವಟಿಕೆಗಳಲ್ಲಿ ತಮ್ಮನ್ನು ತೊಡಗಿಸಿಕೊಂಡು, ಸಾಂದರ್ಭಿಕವಾಗಿ ಆಳುಗಳ ತುಟಾಗ್ರತೆ
            ಉಂಟಾಗಿರುವುದು.
            ಈ ಮೇಲಿನ ಪರಿಣಾಮಗಳಿಂದಾಗಿ, ರೈತ ತನ್ನ ಕೃಷಿ ಚಟುವಟಿಕೆಗಳಲ್ಲಿ ತಂತ್ರಜ್ಞಾನ
            ಅಳವಡಿಸಿಕೊಳ್ಳಲಾಗುತ್ತಿಲ್ಲ, ಕೃಷಿ ಉತ್ಪಾದನಾ ವೆಚ್ಚ ದುಬಾರಿಯಾಗಿ ಪರಿಣಮಿಸಿದೆ. ಹೀಗಾಗಿ ಕೃಷಿ
            ಮಾಡುವುದು, ಯಾವುದೇ ರೀತಿಯಲ್ಲೂ ಲಾಭದಾಯಕವಲ್ಲ ಅನ್ನುವ ಸಂದರ್ಭ ಉಂಟಾಗಿದೆ . ಇಂತಹ
            ಪ್ರತಿಕೂಲ ಪರಿಸ್ಥಿಯನ್ನು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಎದುರಿಸಿ ಮೂಲಭೂತವಾದ ಸಮಸ್ಯೆಗಳಿಗೆ ಶಾಶ್ವತ ಮತ್ತು
            ಸುಸ್ಥಿರವಾದ ಪರಿಹಾರವನ್ನು ಸಾಮೂಹಿಕವಾಗಿ ಕಂಡುಕೊಳ್ಳುವ ನಿಟ್ಟಿನಲ್ಲಿ ಏಳು ವರ್ಷದ ಹಿಂದೆ
            ಪ್ರಾರಂಭವಾದ ಸಂಸ್ಥೆ “ಪ್ರಗತಿಮಿತ್ರ”.
            </p>
      </div>
      <div className='how_we_work'>
        <h3>ಕಂಪನಿಯ ಧ್ಯೇಯೋದ್ದೇಶ</h3>
      </div>
      <div className='container list'>
        <ul>
          <li>
          ಅಡಿಕೆ, ಕಾಳುಮೆಣಸು, ತೆಂಗು ಮತ್ತು ಅನ್ಯ ತೋಟಗಾರಿಕೆ ಬೆಳೆಗಳ ಕೊಯ್ಲು ಮತ್ತು ಕೊಯ್ಲೋತ್ತರ
          ನಿರ್ವಹಣೆಗೆ ಸುಸ್ಥಿರ ಮತ್ತು ಲಾಭದಾಯಕ ಯೋಜನೆಗಳನ್ನು ರೂಪಿಸಿ ಕಾರ್ಯರೂಪಕ್ಕೆ ತರುವುದು.
          </li>
          <li>ಕೃಷಿಕರು ಮತ್ತು ಕೃಷಿ ಅವಲಂಬಿತರಿಗೆ ಸುಸ್ಥಿರ ಜೀವನೋಪಾಯ ನಡೆಸಲು ಸಹಾಯ-ಸಹಕಾರ ಮತ್ತು ಪ್ರೋತ್ಸಾಹ ನೀಡುವುದು.</li>
          <li>ಸಾಮೂಹಿಕ ಕೃಷಿ ಪದ್ಧತಿ ಮತ್ತು ಕೇಂದ್ರಗಳನ್ನು ಪ್ರೋತ್ಸಾಹಿಸುವುದು.</li>
          <li>ಕೃಷಿ ಉದ್ಯಮ ಮತ್ತು ಮೌಲ್ಯವರ್ಧನೆ ಚಟುವಟಿಕೆಗಳಿಗೆ ಪ್ರೋತ್ಸಾಹ.</li>
          <p>
          
          </p>
          <p></p>
        </ul>
      </div>
      <div className='how_we_work'>
        <h3>ಸಹಕಾರ/ಪ್ರಾಯೋಜಕತ್ವ</h3>
      </div>
      <div className='container list'>
        <ul>
          <li>ನಬಾರ್ಡ</li>
          <li>ಎಸ್.3ಐ.ಡಿ.ಎಫ್.</li>
          <li>ನಾಬ್‌ಕಿಸಾನ್</li>
          <li>ಮನುವಿಕಾಸ</li>
          <li>ಎಸ್.ಎಫ್.ಎ.ಸಿ.</li>
          <li>ಬ್ಯಾಂಕ್ ಆಫ್ ಬರೋಡ(ವಿಜಯಾ ಬ್ಯಾಂಕ್)</li>
          <li>ಕೆ.ಎಸ್.ಡಿ.ಎ</li>
          <p>
          
          </p>
          <p></p>
        </ul>
      </div>
      
      
      <Footer />
    </div>
  )
}

export default AboutUs