import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import SignIn from "../src/pages/login/SignIn";

import Home from "./pages/Home/Home";
// import Header from './components/Header/Header';
// import Footer from './components/Footer/Footer';
import ProductList from "./pages/GridView/ProductList";
// import ListView from "./pages/ListView/ListView";
import ProductPage from "./pages/ProductPage/ProductPage";
import SignUp from "./pages/SignUp/SignUp";
import Cart from "./pages/CartPage/Cart";
import Checkout from "./pages/Checkout/Checkout";
// import ListView from './pages/ListView/ListView';
// import ViewToggle from "./pages/Toggel/ViewToggle"
import AboutUs from "./pages/AboutUs/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy/privacy-policy";
import MyProfile from "./pages/MyProfile/MyProfile";
import FAQ from "./pages/FAQ/faq";
import "bootstrap/dist/css/bootstrap.min.css";
import EcomContextProvider from "./context/context";
import PrivateRoutes from "./PrivateRoutes/PrivateRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error404Page from "./pages/Error404Page/Error404Page";
import BestSeller from "./pages/BestSeller/BestSeller";
import Trending from "./pages/Trending/Trending";
import PopularProducts from "./pages/PopularProducts/PopularProducts";
import Offers from "./pages/Offers/Offers";
import FilteredSearch from "./pages/FilteredSearch/FilteredSearch";
import Header from "./components/Header/Header";
import CategoryPage from "./pages/CategoryPage/CategoryPage";
import Otpverification from "./pages/Otpverification/Otpverification";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import CreateReferrals from "./pages/CreateReferrals";

function App() {
  const {t, i18n} = useTranslation();
  useEffect(()=>{
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, [])
    return (
    <EcomContextProvider>
      <Router>

        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/myprofile" element={<MyProfile />} />
            <Route path="/addtocart" element={<Cart />} />
            <Route path="/referrals" element={<Cart />} />
            <Route path="/referandearn" element={<CreateReferrals />} />
          </Route>
          <Route path="/signin" element={<SignIn />} />
          {/* <Route path="/otpverification" element={<Otpverification />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/productpage/:productId" element={<ProductPage />} />
          <Route path="/productgridview" element={<ProductList />} />
          {/* <Route path="/productlistview" element={<ListView />} /> */}
          <Route path="" element={<Navigate to="/" />} />
          <Route path="*" element={<Error404Page />} />
          <Route path="/bestseller" element={<BestSeller />} />
          <Route path="/trending" element={<Trending />} />
          <Route path="/popularproducts" element={<PopularProducts />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/filteredsearch" element={<FilteredSearch />} />
          <Route path="/category/:categoryname" element={<CategoryPage />} />
          
        </Routes>
      </Router>
      <ToastContainer autoClose={1000} />
    </EcomContextProvider>
    
  );
}

export default App;
