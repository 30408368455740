import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import SearchBar from '../../components/SearchBar/SearchBar';
import Footer from '../../components/Footer/Footer';
import "./Trending.scss";
import Loader from '../Loader/Loader';
import { getTrendingProducts } from '../../api/trendingProducts';
import { IMG_URL } from '../../config';
import { useContext } from 'react';
import { EcomContext } from '../../context/context';

const Trending = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { isLoggin }=useContext(EcomContext)

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);

  useEffect(() => {
    const fetchTrendingProducts = async () => {
      try {
        const response = await getTrendingProducts();
        const data = await response.json();
        if (data.type === 'success') {
          setTrendingProducts(data.products);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchTrendingProducts();
  }, []);

 
  return (
    <div>
      <div>
        <Header />
        <SearchBar />
      </div>
      <div className='container trending_header mt-5 mb-3'>
        <h3>Trending Products</h3>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="container product-grid">
              {isLoggin ? (
              trendingProducts && trendingProducts.length > 0 ? (
                trendingProducts.map((product) => (
                  <div className="grid-card" key={product._id}>
                    <div className="grid-img">
                      <img
                        src={`${IMG_URL}/${product.image}`}
                        className="card-img-top"
                        alt={product.title}
                      />
                      <div className="grid-card-div ps-0">
                        <h3>{product.title.substring(0, 15)}</h3>
                        <p className='grid-description'>{product.description.substring(0, 50)}</p>
                        <p className='grid-amount'>₹ {product.price}</p>
                        {/* <button onClick={() => addToCart(product)}>Add to Cart</button> */}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='text-center no_trending'>
                  <p>No trending products are available.</p>
                </div>
              )
            ) : (
              <div className='text-center no_trending'>
                <p>To see the trending products, please login.</p> 
              </div>
            )}
          </div>
        </div>
      )}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Trending;
