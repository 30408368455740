import { BASE_URL } from '../config';
import axios from 'axios';
export const getProfileAsync = async () => {
  const user = sessionStorage.getItem('userId')
  let token = sessionStorage.getItem('token');
  if (token !== null) {
    return fetch(`${BASE_URL}/users/${user}`, {
      method: 'GET',
      headers: {
        token: `${token}`,
        // Authorization: `ADMIN ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error('Token is null'));
  }
};

export const editMyProfileAsync = async ({
  firstName,
  lastName,
  mobile,
  address,
}) => {
  let token = sessionStorage.getItem('token');
  const userId = sessionStorage.getItem('userId');
  if (token !== null) {
    return fetch(`${BASE_URL}/users/${userId}`, {
      method: 'PUT',
      headers: {
        token: `${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName,
        lastName,
        mobile,
        address,
      }),
    });
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error('Token is null'));
  }
};

export const editMyProfilePicAsync = async (data) => {
  const userId = sessionStorage.getItem('userId');
  let token = sessionStorage.getItem('token');
  if (token !== null) {
    return fetch(`${BASE_URL}/users/profile/${userId}`, {
      method: 'POST',
      headers: {
        token: `${token}`,
      },
      body: data,
    });
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error('Token is null'));
  }
};

export const getMyReferrals = async () => {
  const user = sessionStorage.getItem('userInfo');
  const mobile = JSON.parse(user)?.mobile;
  let token = sessionStorage.getItem('token');
  if (token !== null) {
    return fetch(`${BASE_URL}/referrals/${mobile}`, {
      method: 'GET',
      headers: {
        token: `${token}`,
      }
    });
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error('Token is null'));
  }
};

export const createReferralApi = async (data) => {
  let token = sessionStorage.getItem('token');
  if (token !== null) {
    const headers = {
      'token': token, // Example Authorization header
      'Content-Type': 'application/json',      // Setting the content type
    };
    console.log(headers)
    return axios.post(`${BASE_URL}/referrals`, data, { headers: headers });
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error('Token is null'));
  }
};