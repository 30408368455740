import React, { useContext, useEffect, useState } from 'react';
import './MyOrderList.css';
// import leafImg from "../../../assets/leaf.jpg";
// import Header from "../../../components/Header/Header";
// import SearchBar from "../../../components/SearchBar/SearchBar";
// import Footer from "../../../components/Footer/Footer";
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';
// import { IMG_URL } from "../../../config";
import imgOne from '../../../assets/jcb.jpg';
import { BASE_URL } from '../../../config';
import { getOrdersAsync } from '../../../api/order';
import { EcomContext } from '../../../context/context';
import { useTranslation } from 'react-i18next';


const MyOrderList = () => {
  const { t } = useTranslation();
  const {myProducts} = useContext(EcomContext);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '584px',
      transform: 'translate(-50%, -50%)',
    },
  };

  // dummy orders
  const _orders = [
    {
      id: 1,
      image: imgOne,
      _id: 'ABC123',
      address: '123 Main Street',
    },
    {
      id: 2,
      image: imgOne,
      _id: 'DEF456',
      address: '456 Elm Street',
    },
    {
      id: 3,
      image: imgOne,
      _id: 'GHI789',
      address: '789 Oak Street',
    },
    {
      id: 4,
      image: imgOne,
      _id: 'JKL012',
      address: '012 Pine Street',
    },
  ];
  //
  const handleOrderDetailsClick = (order) => {
    setSelectedOrder(order);
  };
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await getOrdersAsync();

        const data = await response.json();

        if (data.type === 'success') {
          setOrders(data.orders);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrder();
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="my-order-list">
          <div className="card card_myOrderlist">
            <h2 className="card-header">{t('My Orders')}</h2>
            {true ? (
              <div className="order-list">
                {/* Assuming `orders` is an object containing order IDs as keys */}
                {Object.keys(orders).map((orderId) => {
                  const order = orders[orderId];

                  return (
                    <div key={order._id} className="order-item">
                      <div>
                        <h5>{t('Order ID')} : {order._id}</h5>
                        <h3>{t('Address')} : {order.address}</h3>
                      </div>
                      <div className="order-details">
                          <button onClick={() => handleOrderDetailsClick(order)}>
                            {t('Order Details')}
                          </button>
                        </div>
                      <div className="order-products">
                        <ul>
                          {order.products.map((product) => {
                            const productName=myProducts.find(item=>item._id===product.productId)
                            const selectedDate=product.selectedDate?new Date(product.selectedDate).toLocaleDateString('en-GB'):"-";
                            return (
                              <li key={product._id}>
                                <p>{t('name')} : {t(productName.title)}</p>
                                <p>{t('Quantity')} : {product.quantity}</p>
                                <p>{t('Selected Date')} : {selectedDate}</p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="no-order-list">{t('No Order List')}</div>
            )}
            {selectedOrder && (
              <Modal
                isOpen={true}
                onRequestClose={() => setSelectedOrder(null)}
                style={customStyles}
              >
                <div className="modal-header">
                  <h2>{t('Order Details')}</h2>
                  <MdClose onClick={() => setSelectedOrder(null)} />
                </div>
                <div className="modal-content">
                  <p>{t('Order ID')}: {selectedOrder._id}</p>
                  <p>{t('Address')}: {selectedOrder.address}</p>
                  <ul>
                    {selectedOrder.products.map((product) => (
                      <li key={product._id}>
                        <span>{t('Service ID')}:</span> {product.productId}{' '}
                        <span>{t('Quantity')}:</span> {product.quantity}
                      </li>
                    ))}
                  </ul>
                </div>
              </Modal>
            )}

            <div className="my-2 order-view-more">{t('view more')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrderList;
