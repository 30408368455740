import React, { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import SearchBar from '../../components/SearchBar/SearchBar';
import Footer from '../../components/Footer/Footer';
import imgOne from '../../assets/imgOne.jpg';
import grid from '../../assets/grid.svg';
import list from '../../assets/list.svg';
import './ProductList.scss';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { updateCart, getCart, createCart } from '../../api/cart';
import { getProducts } from '../../api/products';
import { IMG_URL } from '../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EcomContext } from '../../context/context';
import Loader from '../Loader/Loader';
import { useTranslation } from 'react-i18next';

const ProductList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isLoggin } = useContext(EcomContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [prevCart, setPrevCart] = useState([]);
  const [initialCart, setInitialCart] = useState(false);
  const { setIsFetchCart, isFetchCart, cartItem, myProducts, cartItemProduct } =
    useContext(EcomContext);
  const { product, loggedIn } = props;

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);

  const addToCart = async (productDetails) => {
    if (!isLoggin) {
      navigate('/signin', { state: { from: '/product', product } });
    } else {
      navigate('/addtocart', { state: { from: '/product', product } });
    }

    const initialCart_ = cartItemProduct?.data?.type === 'error';

    if (!initialCart_) {
      setIsLoading(true);

      const productDetail = {
        productId: productDetails._id,
        quantity: 1,
      };

      const existingCartItemIndex = cartItem.findIndex(
        (item) => item.productId === productDetail.productId
      );

      if (existingCartItemIndex >= 0) {
        const updatedCartItem = {
          ...cartItem[existingCartItemIndex],
          quantity:
            cartItem[existingCartItemIndex].quantity + productDetail.quantity,
        };

        const updatedCart = [...cartItem];
        updatedCart[existingCartItemIndex] = updatedCartItem;

        const payload = {
          products: updatedCart.map((product) => ({
            productId: product.productId,
            quantity: product.quantity,
          })),
        };

        setIsLoading(true);
        const updatesCart = await updateCart(payload);
        const data = await updatesCart.json();

        if (data.type === 'success') {
          toast.success('item Added Successfully in the Cart');
          setIsFetchCart((prevData) => !prevData);
          setIsLoading(false);
        }
      } else {
        const filterPrevCart = [
          ...cartItem?.map((cart) => ({
            productId: cart.productId,
            quantity: cart.quantity,
          })),
        ];

        const allProduct = [...filterPrevCart, productDetail];

        const payload = {
          products: allProduct.map((product) => ({
            productId: product.productId,
            quantity: product.quantity,
          })),
        };

        setIsLoading(true);
        const updatesCart = await updateCart(payload);
        const data = await updatesCart.json();

        if (data.type === 'success') {
          toast.success('item Added Successfully in the Cart');
          setIsFetchCart((prevData) => !prevData);
          setIsLoading(false);
        }
      }
    } else {
      const userId = sessionStorage.getItem('userId');
      const products = {
        productId: productDetails._id,
        quantity: 1,
      };

      setIsLoading(true);
      const responseCreateCart = await createCart({ userId, products });
      const createCartdata = await responseCreateCart.json();

      setIsFetchCart((prevData) => !prevData);
      setIsLoading(false);
    }
  };

  const [isFetch, setIsFetch] = useState(false);
  const [view, setView] = useState('grid');
  const [toggle, setToggle] = useState('grid');

  const toggleGridList = () => {
    if (window.innerWidth > 700) {
      setView(view === 'grid' ? 'list' : 'grid');
      setToggle(!toggle);
    }
  };

  const viewFullProduct = (id) => {
    navigate(`/productpage/${id}`);
  };

  return (
    <div>
      <>
        <Header />
        <SearchBar />
      </>
      {isLoading && <Loader />}
      <div className="container">
        <div className="d-flex align-items-baseline justify-content-between">
          <p className="headerName ps-0">{t('Service List')}</p>
          {window.innerWidth > 768 && (
            <button
              onClick={toggleGridList}
              type="button"
              className="toggleBtn"
            >
              {toggle ? (
                <img src={grid} className="img-fluid gridIcon" alt="grid" />
              ) : (
                <img src={list} className="img-fluid listIcon" alt="grid" />
              )}
            </button>
          )}
        </div>
        {view === 'grid' ? (
          <div>
            <div className="product-grid">
              {myProducts.map((product) => (
                <div className="grid-card" key={product._id}>
                  <div className="grid-img">
                    <img
                      src={`${IMG_URL}/${product.image}`}
                      onClick={() => {
                        setIsLoading(true);
                        setTimeout(() => {
                          setIsLoading(false);
                          viewFullProduct(product._id);
                        }, 300);
                      }}
                      alt="product1"
                    />
                  </div>

                  <div className="grid-card-div">
                    <h3>{t(product.title.substring(0, 15))}</h3>
                    <p className="grid-description">
                      {t(product.description.substring(0, 50))}
                    </p>
                    <p className="grid-amount">₹ {product.price}</p>
                    <button onClick={() => addToCart(product)}>
                      {t('Add to Cart')}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="items">
              {myProducts.map((product) => (
                <div className="product_card mt-3 mb-3 product_list_item">
                  <div className="item" key={product._id}>
                    <div
                      className="item-img-name"
                      onClick={() => viewFullProduct(product._id)}
                    >
                      <div className="d-flex justify-content-center align-item-center">
                        <img
                          src={`${IMG_URL}/${product.image}`}
                          alt="product1"
                        />
                        <div className="product_container">
                          <h2 className="ps-3">{t(product.title)}</h2>
                          <p className="product_description ms-3 mb-3">
                            {t(product.description)}
                          </p>
                          <div className="view ms-3 m-0">
                            <a
                              href={`/productpage/${product._id}`}
                              onClick={() => viewFullProduct(product._id)}
                            >
                              {t('View details')}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pe-3">
                      <h3 className="pb-1 ps-5">₹ {product.price}</h3>
                      <button
                        className="btn btn-primary"
                        onClick={() => addToCart(product)}
                      >
                        {t('Add to Cart')}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ProductList;
