import React, { useEffect, useState } from 'react';
import './MyProfile.css';
import profileIcon from '../../assets/profile-user.png'
import cartIcon from '../../assets/shopping-cart.png'
import heartIcon from '../../assets/heart.png'
import MyProfileInfo from './MyProfileInfo/MyProfileInfo';
import MyOrderList from './MyOrderList/MyOrderList';
import MyWishList from './MyWishList/MyWishList';
import Header from '../../components/Header/Header';
import SearchBar from '../../components/SearchBar/SearchBar';
import Footer from '../../components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader/Loader';
import Referrals from './Referrals';

const MyProfile = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState('1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Header />
      <SearchBar />
      {
        <div className="container-fluid">
          <div className="my-profile">
            <div className="sidebar">
              <div
                className={`sidebar-tab ${activeTab === '1' ? 'active' : ''}`}
                onClick={() => handleTabClick('1')}
              >
                <span className='profile-icon'><img src={profileIcon} alt="" /></span> {t('My Profile')}
              </div>
              <div
                className={`sidebar-tab ${activeTab === '2' ? 'active' : ''}`}
                onClick={() => handleTabClick('2')}
              >
                <span className='profile-icon'><img src={cartIcon} alt="" /></span> {t('Order')}
              </div>
              <div
                className={`sidebar-tab ${activeTab === '3' ? 'active' : ''}`}
                onClick={() => handleTabClick('3')}
              >
                <span className='profile-icon'><img src="/assets/images/referral.svg" alt="" /></span> {t('Referrals')}
              </div>
            </div>
            <div className="main-content">
              {activeTab === '1' && (
                <div>
                  <MyProfileInfo />
                </div>
              )}
              {activeTab === '2' && (
                <div>
                  <MyOrderList />
                </div>
              )}
              {activeTab === '3' && (
                <div>
                  <Referrals />
                </div>
              )}
            </div>
          </div>
        </div>


      }
      <div className='container-fluid g-0 mt-5'>
        <Footer />
      </div>
    </div>
  );
};

export default MyProfile;
