import React, { useContext, useEffect, useState } from 'react';
import './home.scss';
import imgOne from '../../assets/jcb.jpg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SearchBar from '../../components/SearchBar/SearchBar';
import { useNavigate } from 'react-router-dom';
import { getCategories } from '../../api/categories';
import { getProducts } from '../../api/products';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { IMG_URL } from '../../config';
import Sidenav from '../../components/SideNav/SideNav';
import { EcomContext } from '../../context/context';
import Loader from '../Loader/Loader';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { categories, filteredSearch, myProducts } = useContext(EcomContext);
  const [carouselData, setCarouselData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);

  const [isFetch, setIsFetch] = useState(false);
  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const token = sessionStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://api.pragatimitra.in/api/v1/carousel',
          {
            headers: {
              token: `${token}`,
            },
          }
        );
        const data = await response.json();
        setCarouselData(data.carousel);
      } catch (error) {
        console.error('Error fetching carousel data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);

        const response = await getProducts();

        const data = await response.json();

        if (data.type === 'success') {
          setProducts([...data.products].reverse());

          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error);

        setIsLoading(false);
      }
    };

    // fetchCategory();

    fetchProducts();
  }, [isFetch]);

  const addtoCart = () => {
    navigate('/addtocart');
  };

  const handleCategoryPage = (category) => {
    const _category = category;
    navigate(`/category/${_category}`);
  };

  // dummy Products
  const carouselData_Jcb = [
    {
      id: 1,
      imgSrc: imgOne,
      // title: 'Product 1',
      // description: 'Product 1 description',
      price: ' 345',
    },
    // {
    //   id: 2,
    //   imgSrc: imgOne,
    //   // title: 'Product 2',
    //   // description: 'Product 2 description',
    //   price: ' 345',
    // },
    // {
    //   id: 3,
    //   imgSrc: imgOne,
    //   // title: 'Product 3',
    //   // description: 'Product 3 description',
    //   price: ' 345',
    // },
    // {
    //   id: 4,
    //   imgSrc: imgOne,
    //   // title: 'Product 4',
    //   // description: 'Product 4 description',
    //   price: ' 345',
    // },
    // {
    //   id: 5,
    //   imgSrc: imgOne,
    //   // title: 'Product 5',
    //   // description: 'Product 5 description',
    //   price: ' 345',
    // },
  ];
  // dummy_productss
  // const _products = [
  //   {
  //     imgSrc: imgOne,
  //     title: "Product 1",
  //     description: "Product 1 description",
  //     price: " 345"
  //   },
  //   {
  //     imgSrc: imgOne,
  //     title: "Product 2",
  //     description: "Product 2 description",
  //     price: " 345"
  //   },
  //   {
  //     imgSrc: imgOne,
  //     title: "Product 3",
  //     description: "Product 3 description",
  //     price: " 345"
  //   },
  //   {
  //     imgSrc: imgOne,
  //     title: "Product 4",
  //     description: "Product 4 description",
  //     price: " 345"
  //   }
  // ];

  // dummy categories
  // const _categories = [
  //   {
  //     id: 1,
  //     name: "Jcb",
  //     image: imgOne
  //   },
  //   {
  //     id: 2,
  //     name: "Mini Hitachi",
  //     image: imgOne
  //   },
  //   {
  //     id: 3,
  //     name: "Tractor",
  //     image: imgOne
  //   },
  //   {
  //     id: 4,
  //     name: "Picker",
  //     image: imgOne
  //   },
  //   {
  //     id: 5,
  //     name: "Tipper",
  //     image: imgOne
  //   },
  //   {
  //     id: 6,
  //     name: "Accessories",
  //     image: imgOne
  //   }
  // ];

  return (
    <div>
      <div className="bannerImage">
        <Header />

        <SearchBar />

        <Carousel
          showThumbs={false}
          autoPlay={true}
          interval={2000}
          infiniteLoop={true}
          transitionTime={10}
        >
          {carouselData_Jcb.map((item, index) => (
            <div key={index}>
              <img src={`${imgOne}`} alt={t(item.title)} />
              <div className="carousel-caption">
                <h3>{t(item.title)}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>

      <div className="d-flex justify-content-center align-item-center rounded-circle categories">


      <div className="categories-content">
            <Link
              to="/referandearn"
            >
              
              <img
               className="rounded-circle circle"
                src={`/assets/images/refer.png`}
               
               />
               
                <p className="text-center">{t('ReferAndEarn')}</p>
                
            </Link>
            
          </div>

        {categories
          ?.slice(0, showAllCategories ? categories.length : 7)
          .map((category) => (
            <div
              className="categories-content"
              key={category?._id}
              onClick={() => handleCategoryPage(category?.title)}
            >
              <img
                src={`${IMG_URL}/${category.image}`}
                alt={t(category?.title)}
                className="rounded-circle circle"
              />

              <p className="text-center">{t(category?.title)}</p>
            </div>
          ))}




      <div className="categories-content">
      
          {categories?.length > 7 && (
            <>
              {!showAllCategories && (
                <button
                  className="btn text-danger"
                  onClick={() => setShowAllCategories(true)}
                >
                  <img
               className="rounded-circle circle"
                src={`/assets/images/more.png`}
               
               />
                  
                 <p> {t('View More')}</p>
                </button>
              )}

              {showAllCategories && (
                <button
                  className="btn  text-danger"
                  onClick={() => setShowAllCategories(false)}
                >
                  <img
               className="rounded-circle circle"
                src={`/assets/images/less.png`}
               
               />                  
                  <p>{t('View Less')}</p>
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <div className="text-center text-black">
        <p className="youMay">{t('You May Be Interested In')}</p>
      </div>

      {/* <div className='container'>
        <div className='product-grids'>
          {
            products.slice(0, 4).map((product, index) => (
              <div className='' key={index} onClick={() => {
                setIsLoading(true);
                setTimeout(() => {
                  navigate(`/productpage/${product._id}`);
                  setIsLoading(false);
                }, 400);
              }}>
                <div className='product-cards'>
                  <div className='product-img'>
                    <img src={`${IMG_URL}/${product.image}`} alt="ProductImage" className='card-img-top-home' />

                  </div>
                  <div className='card-body-home ps-3'>
                    <p className=' productTitle'>{product.title.substring(0, 15)}</p>
                    <p className=''>{product.description.substring(0, 50)}</p>
                    <p className="price ">₹ {product.price}</p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>

      </div> */}

      <div>
        <div className="product-grid">
          {products.slice(0, 4).map((product) => (
            <>
              <div
                className="grid-card"
                key={product._id}
                onClick={() => {
                  setIsLoading(true);
                  setTimeout(() => {
                    navigate(`/productpage/${product._id}`);
                    setIsLoading(false);
                  }, 400);
                }}
              >
                <div className="grid-img">
                  <img
                    src={`${IMG_URL}/${product.image}`}
                    // onClick={() => {
                    //   setIsLoading(true);
                    //   setTimeout(() => {
                    //     setIsLoading(false);
                    //     viewFullProduct(product._id);
                    //   }, 300);
                    // }}
                    alt="product1"
                  />
                </div>

                <div className="grid-card-div">
                  <h3>{t(product.title.substring(0, 15))}</h3>
                  <p className="grid-description">
                    {t(product.description.substring(0, 50))}
                  </p>
                  <p className="grid-amount">₹ {product.price}</p>
                  {}
                </div>
              </div>
            </>
          ))}
        </div>
      </div>

      <div className="container-fluid g-0">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
