import React, { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import SearchBar from '../../components/SearchBar/SearchBar';
import Footer from '../../components/Footer/Footer';
import "./BestSeller.scss";
import { EcomContext } from '../../context/context';
import { IMG_URL } from '../../config';
import Loader from '../Loader/Loader';
import { getBestSeller } from '../../api/bestSeller';

const BestSeller = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { isLoggin } = useContext(EcomContext);

  const [bestSellers, setBestSellers] = useState([]);


  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  useEffect(() => {
    const fetchBestSellers = async () => {
      try {
        const response = await getBestSeller();
        const data = await response.json();
        if (data.type === 'success') {
          setBestSellers(data);
        }

      } catch (error) {
        console.error(error);
      }
    };
    fetchBestSellers();
  }, []);


  return (
    <div>
      <div>
        <Header />
        <SearchBar />
      </div>
      <div className='container bestSeller_header mt-5 mb-3'>
        <h3>Best Seller</h3>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div>
            <div className=" container product-grid">
              {isLoggin ? (
                bestSellers && bestSellers?.data?.length > 0 ? (
                  bestSellers.data.map((product) => {
                    return (
                      <div className="grid-card" key={product._id}>
                        <div className="grid-img">
                          <img
                            src={`${IMG_URL}/${product.image}`}
                            className="card-img-top"
                            alt={product.title}
                          />
                          <div className="grid-card-div ps-0">
                            <h3>{product.title.substring(0, 15)}</h3>
                            <p className='grid-description'>{product.description.substring(0, 50)}</p>
                            <p className='grid-amount'>₹ {product.price}</p>
                           
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className='text-center no_bestsell'>
                    <p>No Best selling products are available.</p>
                  </div>
                )
              ) : (
                <div className='text-center no_trending'>
                  <p>To see the Best selling, please login.</p>
                </div>
              )}
            </div> 
           

        </div>
      )}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default BestSeller;
