import React from "react";
import Header from "../../components/Header/Header";
import SearchBar from "../../components/SearchBar/SearchBar";
import Footer from "../../components/Footer/Footer";
import imgOne from "../../assets/imgOne.jpg";
import "./PopularProducts.scss";
import Loader from "../Loader/Loader";
import { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";

const PopularProducts = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);
  return (
    <Fragment>
      <div>
        <div>
          <Header />
          <SearchBar />
        </div>
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="container popularProducts_header mt-5 mb-3">
                <h3>Popular Products</h3>
              </div>
              <div className="container text-start mb-5">
                <div className="row  row-cols-4">
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container text-start mb-5">
                <div className="row row-cols-4">
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container text-start mb-5">
                <div className="row row-cols-4">
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container text-start mb-5">
                <div className="row row-cols-4">
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card card_popularProducts">
                      <img src={imgOne} className="card-img-top" alt="..." />
                      <div className="card-body ps-0">
                        <h5>$299</h5>
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

export default PopularProducts;
