import React, { useState, useEffect, useContext } from 'react';
import './Checkout.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import SearchBar from '../../components/SearchBar/SearchBar';
import { EcomContext } from '../../context/context';
import { IMG_URL } from '../../config';
import { createOrder } from '../../api/order';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateCart } from '../../api/cart';
import { useNavigate } from 'react-router-dom';
import imgOne from '../../assets/jcb.jpg';
import { useTranslation } from 'react-i18next';

function Checkout() {
  const navigate = useNavigate();
  const data = useContext(EcomContext);
  const { combineData, userInfo, setSavedTransaction, setIsFetchCart } = data;
  const { firstName, lastName, mobile, _id } = userInfo;
  const { t } = useTranslation();
  const [address, setAddress] = useState('');
  const [payementMethod, setPayementMethod] = useState('card');

  const totalPrice = combineData.reduce(
    (accumulator, current) => accumulator + current.price * current.quantity,
    0
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const products = combineData.map((product) => {
      return {
        productId: product.productId,
        quantity: product.quantity,
        selectedDate: product.selectedDate ? product.selectedDate : null,
      };
    });

    const orderData = {
      userId: _id,
      products: products,
      amount: totalPrice,
      address: address,
      status: 'pending',
    };

    try {
      const response = await createOrder(orderData);
      if (response.type === 'success') {
        // setSavedTransaction({ ...response })
        const filterPrevCart = {
          products: [],
        };
        const deleteCartAfterSuccess = await updateCart(filterPrevCart);

        const cartData = await deleteCartAfterSuccess.json();
        if (cartData.type === 'success') {
          toast.success(response.message);
          setIsFetchCart((prevCart) => !prevCart);
          navigate('/');
        }
      }
    } catch (error) {
      toast.error(error);
    }

  };

  return (
    <div>
      <Header />
      <SearchBar />
      <div className="container checkout-page">
        <div className="row ">
          <div className="col-6  ">
            <form className="checkout-form" onSubmit={handleSubmit}>
              <div class="form-group">
                <label for="fullname">{t('Full Name')}</label>
                <div class="horizontal-bar">
                  <span class="input-value">
                    {firstName} {lastName}
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="mobile">{t('Mobile')}</label>
                <div class="horizontal-bar">
                  <span class="input-value">{mobile}</span>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="address">{t('Address')}</label>
                <textarea
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  name="address"
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="payment">{t('Payment Method')}</label>
                <select
                  id="payment"
                  name="payment"
                  value={payementMethod}
                  onChange={(e) => setPayementMethod(e.target.value)}
                >
                  <option value="cash">{t('Cash')}</option>
                </select>
              </div>
              <button type="submit">{t('Place Order')}</button>
            </form>
          </div>
          <div className="col-6 ">
            <div className="checkout-pages">
              <div className="scroll-container">

                {combineData.map((item) => (
                  <div key={item._id} className="cart-product-card">
                    <div className="product-details-container">
                      <div className="product-details-div">
                        <div className="product-image-container">
                          <img
                            src={`${IMG_URL}/${item.image}`}
                            alt={t(item.title)}
                          />
                        </div>
                        <div className="product-info-container">
                          <h3 className="product-name">{t(item.title)}</h3>
                        </div>
                        <div className="product-price">
                          ₹ {item.price * item.quantity}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="total-amount-container">
                <h4>{t('Total')}</h4>

                <div className="total-amount">₹ {totalPrice}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Checkout;
