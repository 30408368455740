// src/components/LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';


function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    if (i18n.language === 'kn') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('kn');
    }
  };

  return (
    <div className="language-switcher">
      <button className="language-button" onClick={toggleLanguage}>
        {i18n.language === 'kn' ?  'English' : 'ಕನ್ನಡ'}
      </button>
    </div>
  );
}

export default LanguageSwitcher;