import React, { useEffect, useContext, useState } from 'react';
import './Cart.css';
import Header from '../../components/Header/Header';
import SearchBar from '../../components/SearchBar/SearchBar';
import Footer from '../../components/Footer/Footer';
// import leaf from "../../assets/leaf.jpg"
import { Link, useNavigate } from 'react-router-dom';
import { deleteCart, getCart, updateCart } from '../../api/cart';
// import { getProducts } from "../../api/products"
// import remove from "../../assets/Remove.svg"
import { IMG_URL } from '../../config';
import { EcomContext } from '../../context/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Loader/Loader';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';


const Cart = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showAllItems, setShowAllItems] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleContinueShopping = () => {
    // Redirect to /productgridview
    navigate('/productgridview');
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  const maxItemsToShow = 3;
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);
  const cartData = useContext(EcomContext);
  const { combineData, setCombineData, setIsFetchCart, isLoggin, cartItem } =
    cartData;

  const displayedItems = showAllItems
    ? combineData
    : combineData.slice(0, maxItemsToShow);

  const toggleShowAllItems = () => {
    setShowAllItems(!showAllItems);
  };

  const userId = sessionStorage.getItem('userId');
  // const token = localStorage.getItem("token")

  // const [isFetch, setIsFetch] = useState(false)
  // const [getProduct, setGetProduct] = useState([])
  // const [combineData,setCombineData]=useState([])
  // const [item, setItem] = useState([])
  // const [isChanged, setIsChanged] = useState(false)

  useEffect(() => {
    if (cartItem?.length === 0) {
      setCombineData([]);
    }
    // setCombineData(combinedData)
  }, [cartItem, setCombineData, isLoggin]);
  const navigate = useNavigate();

  // const itemCount = combineData.reduce(
  //   (total, item) => total + item.quantity,
  //   0
  // )
  const itemCount = combineData.length;

  const totalAmount = combineData.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const onProceedCheckout = () => {
    cartItem.length === 0
      ? toast.warning('There is no items in the Cart')
      : navigate('/checkout');
    // setCombineData([...combinedData])
  };
  const onDeleteItem = async () => {
    setIsFetchCart((prevCart) => !prevCart);

    const responseDeletCart = await deleteCart(userId);
    const responseDeletCartData = await responseDeletCart.json();
    if (responseDeletCartData.type === 'success') {
      toast.success(responseDeletCartData.message);
      setIsFetchCart((prevCart) => !prevCart);
    }
    // setIsFetch(!isFetch)
  };
  const handleParticularProduct = async (id) => {
    const filterItem = cartItem?.filter((item) => item._id !== id);

    const filterPrevCart = {
      products: [
        ...filterItem.map((cart) => {
          return {
            productId: cart.productId,
            quantity: cart.quantity,
            selectedDate:cart.selectedDate?cart.selectedDate:null
          };
        }),
      ],
    };
    setIsLoading(true);

    const updatesCart = await updateCart(filterPrevCart);
    const data = await updatesCart.json();
    if (data.type === 'success') {
      setIsLoading(false);
      toast.success('item deleted successfully');
      setIsFetchCart((prevData) => !prevData);
    }
  };

  const handleQuantityChange = async (event, itemId) => {
    let newCartItem = [];
    const newQuantity = parseInt(event.target.value);
    newCartItem = cartItem.map((item) => {
      if (item._id === itemId) {
        return { ...item, quantity: newQuantity }; // Update the quantity for the selected item
      }
      return item;
    });
    const filterPrevCart = {
      products: [
        ...newCartItem.map((cart) => {
          return {
            productId: cart.productId,
            quantity: cart.quantity,
            selectedDate:cart.selectedDate?cart.selectedDate:null
          };
        }),
      ],
    };
    setIsLoading(true);

    const updatesCart = await updateCart(filterPrevCart);
    const data = await updatesCart.json();
    if (data.type === 'success') {
      setIsLoading(false);
      toast.success('item updated successfully');
      setIsFetchCart((prevData) => !prevData);
    }
    // callAPI(itemId, newQuantity); // Call the API with the updated quantity
  };
  // useEffect(() => {}, [])
  return (
    <>
      <div>
        <Header />
        <SearchBar />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="container-fluid ">
            <div className="container">
              <div className="row">
                <div className="item-cart-text my-4 align-self-start">
                  <h1>
                    {t('Items In Your Cart')} <span>({itemCount})</span>
                  </h1>
                </div>
                <div className="cart-items-container">
                  {combineData.length === 0 ? (
                    <h5 className="text-center">{t('No Item In Your Cart')}</h5>
                  ) : (
                    displayedItems.map((item) => (
                      <div
                        key={item._id}
                        className="row  mb-4 border shadow-lg p-3 bg-white rounded cart-item"
                      >
                        <div className="col-12 d-flex justify-content-between align-items-center">
                          <div className="d-flex py-3 align-items-center ">
                            <div className="image">
                              <img
                                src={`${IMG_URL}/${item.image}`}
                                alt="imag not found"
                              />
                            </div>
                            <div className="mx-3 col align-items-center product-name-content">
                              <div className="product-name">{t(item.title)}</div>
                              <div className="price">{t('Price')} ₹ {item.price}</div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className=" item-count">
                              <span className="qty">Qty :</span>
                              {/* {item.quantity} */}
                              <select
                                value={item.quantity}
                                onChange={(event) =>
                                  handleQuantityChange(event, item._id)
                                }
                              >
                                {[...Array(150).keys()].map((value) => (
                                  <option key={value + 1} value={value + 1}>
                                    {value + 1}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <span className="total-price mx-2">
                              ₹ {item.price * item.quantity}
                            </span>
                            <span className="removeIcon" onClick={() => {}}>
                              <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() =>
                                  handleParticularProduct(item._id)
                                }
                                style={{ color: 'red', fontSize: '25px' }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                {combineData.length > maxItemsToShow && (
                  <p className="view-more-button" onClick={toggleShowAllItems}>
                    {showAllItems ? 'View Less' : 'View More'}
                  </p>
                )}
              </div>
              {/* {combineData.length === 0 ? "" : (
                <button
                  className="delete-btn btn btn-primary"
                  onClick={onDeleteItem}
                >
                  Delete All Cart
                </button>
              )} */}
            </div>

            <div className="container my-4 ">
              {cartItem?.length === 0 || undefined ? (
                ''
              ) : (
                <>
                  <div className="line my-4"></div>
                  <div className="total-amount">
                    <div>
                      <h3>{t('Total')}</h3>
                    </div>
                    <div>
                      <p className="total--price">₹ {totalAmount}</p>
                    </div>
                  </div>
                </>
              )}
              <div className="text-center">
                {cartItem?.length === 0 || undefined ? (
                  ''
                ) : (
                  <button className="checkout-btn" onClick={openModal}>
                    {t('Proceed to Checkout')}
                  </button>
                )}
                <Modal
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                  style={customStyles}
                >
                  <div>
                    <h2>{t('Confirm Booking')}</h2>
                    <p>{t('Do you want to confirm the booking')}?</p>
                    <div className="modal-buttons">
                      <button
                        onClick={() => {
                          closeModal();
                          navigate('/checkout');
                          // toast.success('Order confirmed!', {
                          //   position: 'top-right',
                          // });
                        }}
                      >
                        {t('Yes')}
                      </button>
                      <button onClick={closeModal}>{t('No')}</button>
                    </div>
                    <p
                      className="continue-shopping"
                      onClick={handleContinueShopping}
                    >
                      {t('Continue shopping')}
                    </p>
                  </div>
                </Modal>
                <div className="continue-shopping my-3">
                  <Link to="/productgridview">{t('Continue shopping')}</Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="container-fluid g-0 mt-5">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Cart;
