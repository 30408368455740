import React, { useState, useEffect } from 'react';
import { FaBars, FaShoppingCart, FaUser } from 'react-icons/fa';
import { Link, NavLink, useNavigate, useNavigation } from 'react-router-dom';
import Logo from '../../assets/newlogoss.png';
import './Header.scss';
// import { useContext } from 'react';
// import { EcomContext } from '../../context/context';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useContext } from 'react';
import { EcomContext } from '../../context/context';
import LanguageSwitcher from '../../LanguageSwitcher';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const { isLoggin, setIsLoggin, logoutHandler,userInfo, combineData } = useContext(EcomContext)
  const itemCount = combineData.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const token=sessionStorage.getItem('token')

  // Function to close the mobile menu when a link is clicked
  function handleLinkCartClick() {
    setIsMenuOpen(false);
    if (!token) {
      navigate('/signin'); // Replace '/signin' with the actual login page route
      return;
    }
    navigate('/addToCart');
  }
  function handleLogout() {
    setLoggedIn(false);
    toast.success("successfully Logged out")
    setUsername('');
    logoutHandler()
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('password');
    navigate('/signin')
  }
  return (
    <nav className="navbar navFix">
      <div className="navbar-container container">
        
        
        <div
          className={`navbar-menu-toggle ${isMenuOpen ? 'open' : ''}`}
          onClick={() => setIsMenuOpen((menu)=>!menu)}
        >
          <FaBars />
        </div>

        <div className="navbar-brand">
          <NavLink to="/">
            <img src={Logo} alt="Logo" className="navlogo" />
          </NavLink>
        </div>

        <ul className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
          <li className="navbar-link">
            <Link to="/" onClick={() => setIsMenuOpen((menu)=>!menu)}>
              {t('Home')}
            </Link>
          </li>
          <li className="navbar-link">
            <Link
              to="/productgridview"
              onClick={() => setIsMenuOpen((menu)=>!menu)}
            >
              {t('Service')}
            </Link>
          </li>
          <li className="navbar-link">
            <Link to="/category/JCB" onClick={() => setIsMenuOpen((menu)=>!menu)}
            >
              {t('Jcb')}
            </Link>
          </li>
          <li className="navbar-link">
            <Link
              to="/category/Mini Hitachi"
              onClick={() => setIsMenuOpen((menu)=>!menu)}
            >
              {t('Mini Hitachi')}
            </Link>
          </li>
          <li className="navbar-link">
            <Link
              to="/category/Tractor"
              onClick={() => setIsMenuOpen((menu)=>!menu)}
            >
              {t('Tractor')}
            </Link>
          </li>
          <li className="navbar-link">
            <Link
              to="/category/Goods Vehicle"
              onClick={() => setIsMenuOpen((menu)=>!menu)}
            >
              {t('Goods Vehicle')}
            </Link>
          </li>

          <li className="navbar-link">
            <Link
              to="/category/Tipper"
              onClick={() => setIsMenuOpen((menu)=>!menu)}
            >
              {t('Tipper')}
            </Link>
          </li>
          <li className="navbar-link">
            <Link
              to="/category/Accessories"
              onClick={() => setIsMenuOpen((menu)=>!menu)}
            >
              {t('Accessories')}
            </Link>
          </li>
          {/* <li className="navbar-link">
            <Link
              to="/referandearn"
              onClick={() => setIsMenuOpen((menu)=>!menu)}
            >
              {t('ReferAndEarn')}
            </Link>
          </li> */}
          
          {isLoggin ? (
            <>
              <li className="navbar-link">
                <span
                  className="username-initial"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate('/myProfile');
                  }}
                >
               {userInfo ? userInfo?.firstName?.charAt(0) :"" }
                </span>
              </li>
              <li className="navbar-link">
                <span onClick={handleLogout} className='navbar-icon logout-btn'>
                  <img src="/assets/images/logout.svg" />
                </span>
              </li>
            </>
          ) : (
            <>
              <li className="navbar-link">
                <a href="/signup" className="navbar-icon">
                  <FaUser />
                </a>
              </li>
            </>
          )}
          <li className="navbar-link">
            <button
              className="navbar-icon btn-cart"
              onClick={handleLinkCartClick}
            >
              <FaShoppingCart />
              <span className="cart-count">{itemCount}</span>
            </button>
          </li>
        </ul>
        <LanguageSwitcher /> {/* Add the LanguageSwitcher component */}
      </div>
    </nav>
  );
}




export default Header;
