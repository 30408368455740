import React, { useContext, useEffect, useState } from 'react';
// import heart from "../../assets/Heart.svg";
import './ProductPage.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import SearchBar from '../../components/SearchBar/SearchBar';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getParticularProduct, getProducts } from '../../api/products';
import { IMG_URL } from '../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EcomContext } from '../../context/context';
import { createCart, updateCart } from '../../api/cart';
import moment from 'moment';
import { useTranslation } from 'react-i18next';


const ProductPage = () => {
  const [selectedDate, setSelectedDate] = useState('');
  const { t } = useTranslation();
  const currentDate = new Date().toLocaleDateString('en-CA');

  const handleContinueShopping = () => {
    // Redirect to /productgridview
    navigate('/productgridview');
  };
  const holidayDates = [
    new Date('2023-08-25'), // Christmas
    new Date('2023-08-11'), // New Year's Day
    // Add more holiday dates as needed
  ];
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  const { productId } = useParams();
  const { isLoggin } = useContext(EcomContext);

  const navigate = useNavigate();
  const data = useContext(EcomContext);
  const { cartItem, cartItemProduct, myProducts, setIsFetchCart } = data;

  const [isFetch, setIsFetch] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState({});
  const [remainProduct, setRemainProduct] = useState([]);
  const handleQuantityDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const shareUrl = window.location.href;
  const title = 'Product Title';
  const imageUrl = 'https://example.com/product-image.jpg';
  const handleQuantityIncrease = () => {
    if (quantity < 100) {
      setQuantity(quantity + 1);
    }
  };
  const handleDateChange = (date) => {
    // If productStartDate and productEndDate are available, prevent selecting dates outside the range

    setSelectedDate(date);
  };
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const _id = sessionStorage.getItem('productId');
        if (_id) {
          const response = await getProducts();

          const data = await response.json();
          if (data.type === 'success') {
            // const product=data?.products.find(prod=>prod._id===_id)
            const remainProduct = data?.products.filter(
              (prod) => prod._id !== productId
            );
            // setItem(...data.products)
            // setItem([...data.products].reverse());
            // setIsChanged(!isChanged)
            // setGetProduct([...data?.products])
            // setCount(count => count + 1);
            // setProduct(product)
            setRemainProduct([...remainProduct]);
          }
        }
      } catch (error) {
        toast.error(error);
      }
    };
    const fetchParticularProduct = async () => {
      try {
        const id = productId ? productId : '';
        const response = await getParticularProduct(id);

        const data = await response.json();

        if (data.type === 'success') {
          setProduct(data.product);
        }
      } catch (error) {
      }
    };
    fetchProducts();
    fetchParticularProduct();
  }, [isFetch, productId]);

  function handleQuantityChange(event) {
    const quan = event.target.value;
    setQuantity(quan);
  }

  const addToCart = async (productDetails) => {
    const token = sessionStorage.getItem('token');

    if (!selectedDate) {
      toast.error('Please select a date before booking!');
      return;
    }
    if (!token) {
      // No token found, user is not authenticated. Redirect to the login page.
      navigate('/signin'); // Replace '/signin' with the actual login page route
      return;
    }
    if (!isLoggin) {
      navigate('/signin', { state: { from: '/product', product } });
    } else {
      // If user is logged in, redirect to add-to-cart page
      navigate('/addtocart', { state: { from: '/product', product } });
    }
    // const initialCart = cartItem.length === 0 ? true : false;
    const initialCart_ =
      cartItemProduct?.data?.type === 'error' || cartItem === undefined
        ? true
        : false;

    if (!initialCart_) {
      const productDetail = {
        productId: productDetails._id,
        quantity: parseInt(quantity),
        selectedDate: selectedDate ? selectedDate : null,
      };
      // check if the product is already in the cart
      const existingCartItemIndex = cartItem
        ? cartItem.findIndex(
            (item) => item.productId === productDetail.productId
          )
        : -1;

      if (existingCartItemIndex >= 0) {
        // if the product already exists, update the quantity
        const updatedCartItem = {
          ...cartItem[existingCartItemIndex],
          quantity:
            cartItem[existingCartItemIndex].quantity + productDetail.quantity,
          selectedDate: cartItem[existingCartItemIndex].selectedDate
            ? cartItem[existingCartItemIndex].selectedDate
            : productDetail.selectedDate,
        };
        const updatedCart = [...cartItem];
        updatedCart[existingCartItemIndex] = updatedCartItem;

        const payload = {
          products: updatedCart.map((product) => ({
            productId: product.productId,
            quantity: product.quantity,
            selectedDate: product.selectedDate ? product.selectedDate : null,
          })),
        };

        const updatesCart = await updateCart(payload);
        const data = await updatesCart.json();

        if (data.type === 'success') {
          setIsFetchCart((prevData) => !prevData);
          navigate('/addToCart');
        }
      } else {
        const filterPrevCart = [
          ...cartItem?.map((cart) => ({
            productId: cart.productId,
            quantity: cart.quantity,
            selectedDate: cart.selectedDate ? cart.selectedDate : null,
          })),
        ];
        const allProduct = [...filterPrevCart, productDetail];

        const payload = {
          products: allProduct.map((product) => ({
            productId: product.productId,
            quantity: product.quantity,
            selectedDate: product.selectedDate ? product.selectedDate : null,
          })),
        };

        const updatesCart = await updateCart(payload);
        const data = await updatesCart.json();

        if (data.type === 'success') {
          setIsFetchCart((prevData) => !prevData);
          navigate('/addToCart');
        }
      }
    } else {
      const userId = sessionStorage.getItem('userId');
      const products = {
        productId: productDetails._id,
        quantity: 1,
        selectedDate: selectedDate ? selectedDate : null,
      };
      const responseCreateCart = await createCart({ userId, products });
      const createCartdata = await responseCreateCart.json();
      setIsFetchCart((prevData) => !prevData);
    }
  };
  const filterDate = (date) => {
    // Apply custom class to filtered dates
    if (product.startDate === undefined || product.endDate === undefined) {
      return date;
    }
    if (product.startDate && product.endDate) {
      const productStartDate = new Date(product.startDate);
      productStartDate.setDate(productStartDate.getDate() - 1);
      const productEndDate = new Date(product.endDate);

      return date < productStartDate || date > productEndDate
        ? 'red-date'
        : undefined;
    }
    // No filter if startDate and endDate are not available
  };

  return (
    <div>
      <div className=" mb-5">
        <Header />
        <SearchBar />
      </div>

      <div className="container d-flex justify-content-center mt-5">
        <div className="row">
          <div className="col-md-6 col-xs-6 col-sm-6">
            <div className="item_card">
              <img
                src={`${IMG_URL}/${product.image}`}
                className="card-img-top__product_page p-3"
                alt="YourImage"
              />
              <div className="card-body__product_page">
                <img
                  src={`${IMG_URL}/${product.image}`}
                  className="subImage"
                  alt="YourImage"
                />
                <img
                  src={`${IMG_URL}/${product.image}`}
                  className="subImage"
                  alt="YourImage"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 product-det">
            <div className="mt-3">
              <h3>{t(product.title)}</h3>
              </div>
            <div className="star">
              <p class="star">&#9733;</p>
              <p class="star">&#9733;</p>
              <p class="star">&#9733;</p>
              <p class="star">&#9733;</p>
              <p class="star">&#9733;</p>
              <span>{t('222 Reviews')}</span>
            </div>
            <div className="price">
              <h3 className="ps-3">₹ {product.price}</h3>
            </div>
            <div className="mrp">
              <p className="ps-3">{t('Inclusive of all taxes')}</p>
              {/* <ul>
                <li className="ms-3">Shipping Rs 79 for entire order</li>
                <li className="ms-3">Dispatch in 5-8 Days</li>
                <li className="ms-3">Country of origin India</li>
                <li className="ms-3">Today's Offer</li>
              </ul> */}
            </div>
            <div className="freeShipping g-0 m-0">
              <ul>
                <li> {t('Spcial Discount Availavbe only For Members')}</li>
                <p></p>
                <li> {t('Become Member And Get Exclusive Offers')}</li>
              </ul>
            </div>
            <div className="checkDetails">
              <ul>
                <li> {t('Check Details On Cart Page')}</li>
              </ul>
              <p>
              {t('Images Are For Reference Purpose Only. Actual Product May Vary In Shape Or Appearance Based On Climate, Age, Height Etc.')}
              </p>
            </div>

            <div>
              <p className="description">{t('Description')}</p>
              <p className="">{t(product.description)}</p>
            </div>
            <div className="calendar__container">
              <label htmlFor="bookingDate" className="calendar__label">
                {t('Booking Dates')}
              </label>
              <div className="calendar__input-wrapper">
                <div>
                  <span className="calendar__date-label">{t('Start Date')} :</span>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    minDate={new Date()} // Set the minimum date to today
                    dateFormat="dd/MM/yyyy" // Set the desired date format
                    placeholderText="Select a date"
                    filterDate={filterDate} // Apply the filter function
                    className="custom-datepicker" // Apply custom CSS class
                    excludeDateIntervals={[
                      {
                        start: moment(product.startDate).local().toDate(),
                        end: moment(product.endDate).local().toDate(),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => addToCart(product)}
            >
              {t('Book For Me')}
            </button>

            <button
              type="button"
              className="btn ms-2 btn-success"
              onClick={() => (window.location.href = 'tel:+918762771063')}
            >
              {t('Tatkal Booking')}
            </button>
          </div>
        </div>
      </div>

      <div className="container-fluid g-0 mt-5">
        <Footer />
      </div>
    </div>
  );
};

export default ProductPage;
