import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import imgbg from '../../assets/jcb.jpg';

// import { useHistory } from 'react-router-dom';
import './SignUp.scss';
import greenIndia from '../../assets/greenIndia.svg';
import facebook from '../../assets/facebook.png';
import google from '../../assets/google.png';
import logo from '../../assets/newlogoss.png';
import { signup } from '../../api/signup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// import SignIn from '../login/SignIn';

function SignUp() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');

  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleInputChange1 = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    setPassword1(inputValue);
  };

  const handleInputChange2 = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    setPassword2(inputValue);
  };
  const handleInputNumber = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    setMobile(inputValue);
  };

  // const [success, setSuccess] = useState('');
  const phoneRegex = /^[0-9]*$/;

  const handleSignupForm = async (e) => {
    e.preventDefault();

    if (password1 !== password2) {
      toast.error('Passwords do not match');
      return;
    }

    // if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=.*[^\dA-Za-z]).{6,10}$/.test(password)) {
    //   toast.error('Password should have minimum length 6 and maximum length 10 characters and also include minimum of one special character, one uppercase letter, one lowercase letter, and one number.');

    //   return;

    // }
    if (!/^\d{4}$/.test(password1)) {
      toast.error('Please enter a 6-digit number as the password.');
      return;
    }

    const data = {
      firstName,
      lastName,
      mobile,
      password: password1,
    };

    try {
      const responseSignUp = await signup(data);

      const responseData = await responseSignUp.json();

      if (responseData.type === 'success') {
        setError('');
        setFirstName('');
        setLastName('');
        setMobile('');
        setPassword1('');
        setPassword2('');
        toast.success(responseData.message);
        navigate('/signin');
      } else {
        toast.error(responseData.message);
      }
      // toast.success('User Registered Successfully')
      // navigate('/signin');
    } catch (error) {
      toast.error('An error occurred. Please try again later.');
      toast.error(error);
    }
  };
  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    // <div className='vh-100 signup_container'>
    //   <div class="grid-container">
    //     <div class="grid-item">
    //       <a href="home"> <img src={logo} alt="logo" className='logo' /> </a>
    //       <img src={greenIndia} alt="imageOne" className='img-fluid grid-img image' />
    //       <h3 className='hara'>Hara Hai Toh, Bhara Hai</h3>
    //       <h4 className='challenge'>#GreenIndiaChanllenge</h4>
    //     </div>
    //     <div class="grid-item grid-form pt-5 pb-5">
    //       <div className='text-white'>
    //         <h2>Sign Up</h2>
    //       </div>
    //       <form className='form' onSubmit={handleSubmit}>
    //         <div className='d-flex  justify-content-start align-item-center w-75 signup_inputs'>
    //           <div className='me-2'>
    //             <label className='d-flex justify-content-start  align-item-center' for="fname">First Name:</label>
    //             <input type="text" placeholder="First Name" required className='' onChange={(e) => setFirstName(e.target.value)} />
    //           </div>
    //           <div className=''>
    //             <label className='d-flex justify-content-start  align-item-center' for="lname">Last Name:</label>
    //             <input type="text" placeholder="Last Name" required className='' onChange={(e) => setLastName(e.target.value)} />
    //           </div>
    //         </div>
    //         <div className='w-75 signup_inputs'>
    //           <label className='d-flex justify-content-start  align-item-center' for="phnonenumber">Phone Number:</label>
    //           <input type="text" placeholder="Phone Number" required className='' maxLength={10} pattern="[0-9]{10}" onChange={(e) => setMobile(e.target.value)} />
    //           <label className='d-flex justify-content-start  align-item-center' for="email">Email:</label>
    //           <input type="email" placeholder="Email" required className='' onChange={(e) => setEmail(e.target.value)} />
    //           <label className='d-flex justify-content-start  align-item-center' for="password">Password:</label>
    //           <input type="password" placeholder="Password" required className='' onChange={(e) => setPassword(e.target.value)} />
    //           <FontAwesomeIcon
    //             icon={showPassword ? faEyeSlash : faEye}
    //             className="eye-icon"
    //             onClick={handleShowPassword}
    //           />
    //           <label className='d-flex justify-content-start  align-item-center' for="confirmPassword">Confirm Password:</label>
    //           <input type="password" placeholder="Confirm Password" required className='' onChange={(e) => setConfirmPassword(e.target.value)} />
    //           <FontAwesomeIcon
    //             icon={showPassword ? faEyeSlash : faEye}
    //             className="eye-icons"
    //             onClick={handleShowPassword}
    //           />
    //         </div>
    //       <div className='facebook'>
    //           <button type="submit" class="btn btn-light">SignUp</button>
    //         <span>Or</span>
    //         <img src={facebook} class="img-fluid fb" alt="logo" />
    //         <img src={google} class="img-fluid fb" alt="logo" />
    //         </div>
    //       </form>
    //       <div className='d-flex justify-content-start align-item-center user-container'>
    //         <div className='user'>
    //           <h4>Already have an account</h4>
    //         </div>
    //         <div>
    //           <button type="button" class="btn btn-light newAccount" onClick={()=>navigate('/signin')
    //           } >signIn</button>
    //         </div>

    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="signup-container">
      <div className="signup-left">
        <a href="/" className="logo">
          <img src={logo} alt="logo" />
        </a>

        <img src={imgbg} alt="background" className="bg-img" />
      </div>
      <div className="signup-right">
        <h2>Sign Up</h2>
        <form onSubmit={handleSignupForm}>
          <input
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First Name"
            required
            className=""
          />
          <input
            type="text"
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last Name"
            required
            className=""
          />
          <input
            type="text"
            value={mobile}
            placeholder="Phone Number"
            onChange={handleInputNumber}
            required
            className=""
            maxLength={10}
            pattern="[0-9]{10}"
          />
          <div className="password-container">
            <input
              type={showPassword1 ? 'text' : 'password'}
              placeholder="Enter Pin Number"
              value={password1}
              onChange={handleInputChange1}
              required
              pattern="[0-9]{4}"
              maxLength={4}
            />
            <span className="password-toggle">
              <FontAwesomeIcon
                icon={!showPassword1 ? faEyeSlash : faEye}
                className="eye-icons"
                onClick={togglePasswordVisibility1}
              />
            </span>
          </div>

          <div className="password-container">
            <input
              type={showPassword2 ? 'text' : 'password'}
              placeholder="Re Enter Pin Number"
              value={password2}
              onChange={handleInputChange2}
              required
              pattern="[0-9]{4}"
              maxLength={4}
            />
            <span className="password-toggle">
              <FontAwesomeIcon
                icon={!showPassword2 ? faEyeSlash : faEye}
                className="eye-icons"
                onClick={togglePasswordVisibility2}
              />
            </span>
          </div>

          <button
            type="submit"
            // onClick={() => navigate('/')}
          >
            Sign Up
          </button>
        </form>
        <h5 className="mt-5">OR</h5>
        {/* <div className="social-icons mb-3">
          <img src={facebook} class="img-fluid fb" alt="logo" />
          <img src={google} class="img-fluid fb" alt="logo" />
        </div> */}
        <p>
          Already have an account? <Link to="/signin">Sign in</Link>
        </p>
      </div>
    </div>
  );
}

export default SignUp;
