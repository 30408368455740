import { BASE_URL } from '../config';

export const login = async (payload) => {
  return fetch(`${BASE_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
}

export const signInWithSocialMedia = async (payload) => {
  return fetch(`${BASE_URL}/auth/loginsocialmedia`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
}