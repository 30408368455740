import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './MyProfileInfo.css';
import leafImg from '../../../assets/leaf.jpg';
import Header from '../../../components/Header/Header';
import SearchBar from '../../../components/SearchBar/SearchBar';
import Footer from '../../../components/Footer/Footer';
import Loader from '../../Loader/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { BASE_URL, IMG_URL } from '../../../config';
import user from '../../../assets/person.png';
import { useContext } from 'react';
import { EcomContext } from '../../../context/context';
import { editMyProfileAsync, editMyProfilePicAsync, getProfileAsync } from '../../../api/profile';

const MyProfileInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [mobile, setMobile] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [isFetch, setIsFetch] = useState(false);
  const { t } = useTranslation();
  const [imageFile, setImageFile] = useState(null);

  const userId = sessionStorage.getItem('userId');
  const token = sessionStorage.getItem('token');

  const openModal = () => {
    setModalIsOpen(true);
    setFirstName(userData.firstName);
    setLastName(userData.lastName);
    setAddress(userData.address);
    // setEmail(userData.email);
    // setLastName(userData.lastName);
    setMobile(userData.mobile);
    setImageFile(null);
  };
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleFileSelect = async (event) => {
    setImageFile(event.target.files[0]);

    const data = new FormData();
    const imageData = event.target.files[0];
    data.append('image', imageData);

    const resData = await editMyProfilePicAsync(data)

    const resJsonData = await resData.json();

    if (resJsonData.type === 'success') {
      toast.success(resJsonData.message);
      setIsFetch((fetch) => !fetch);
      // closeModal();
    }
  };

  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);
      const profileData={
        firstName,
        lastName,
        image: imageFile,
        address,
      }
      const response = await editMyProfileAsync(profileData);

      if (!response.ok) {
        setIsLoading(false);
        throw new Error('Failed to update profile');
      }
      const data = await response.json();

      //   // const updatedUserData = {
      //   //   ...userData,
      //   //   firstName,
      //   //   lastName,
      //   //   email: userData.email,
      //   //   mobile: userData.mobile,
      //   //   address: userData.address,
      //   //   image: userData.image
      //   // };

      closeModal();
      setIsLoading(false);
      setIsFetch((fetch) => !fetch);
      toast.success(data.message);
      setUserData(data.updatedUser);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleEmailChange = (event) => {
    // setUserData({ ...userData, email: event.target.value });
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    // setUserData({ ...userData, mobile: event.target.value });
    setMobile(event.target.value);
  };

  const handleAddressChange = (event) => {
    // setUserData({ ...userData, address: event.target.value });
    setAddress(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    setImageFile(selectedFile);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsLoading(true);
        const response = await getProfileAsync();

        const data = await response.json();
        if (data.type === 'success') {
          setIsLoading(false);
          setUserData(data.data);
          setFirstName(data.data.firstName);
          setLastName(data.data.lastName);
          setAddress(data.data.address);
          // setEmail(data.data.email);
          setMobile(data.data.mobile);
          setImageFile(data.data.image); // Make sure imageFile holds the selected image file
        }
        
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };

    fetchUser();
  }, [userId, token, isFetch, userData.firstName, userData.lastName, userData.address, userData.mobile]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className="my-profile-info">
        <div className="container container-s">
          <div className="profile">
            <div className="profile-header">
              {/* <img src={`${IMG_URL}/${userData.image}`} alt="Profile Picture" /> */}
              <h1>
                {firstName} &nbsp; {lastName}
              </h1>
            </div>
            <div className="profile-body">
              <div className="profile-info">
                <h2>{t('Personal Information')}</h2>
                <ul>
                  {/* <li>
                    <span>Email:</span> {email}
                  </li> */}
                  <li>
                    <span>{t('Mobile')} :</span>{mobile}
                  </li>
                </ul>
              </div>
              {/* <div className="profile-address">
                <h2>Shipping Address</h2>
                <p>{address}</p>
              </div> */}
            </div>
            <button onClick={openModal} className="edit-profile-btn">
              {t('Edit Profile')}
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <h2>{t('Edit Profile')}</h2>
        <form className="edit-profile-form">
          <div>
          {/* <label htmlFor="avatar">Avatar:</label>
            <input
              type="file"
              id="avatar"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
            /> */}
          </div>
          <div>
            <label htmlFor="firstName">{t('First Name')}:</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </div>
          <div>
            <label htmlFor="lastName">{t('Last Name')}:</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={lastName}
              onChange={handleLastNameChange}
            />
          </div>
          
          <div>
            <label htmlFor="phone">{t('Mobile')}:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={mobile}
              onChange={handlePhoneChange}
            />
          </div>
          <div>
            {/* <label htmlFor="address">Address:</label>
            <textarea
              id="address"
              name="address"
              value={address}
              onChange={handleAddressChange}
            ></textarea> */}
          </div>
        </form>
        <button className="button-cancel" onClick={closeModal}>
          {t('Cancel')}
        </button>
        <button className="button-save" onClick={handleSaveChanges}>
          {t('Save Changes')}
        </button>
      </Modal>
    </div>
  );
};

export default MyProfileInfo;
