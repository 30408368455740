import React, { useState, useEffect, useContext } from 'react';
import searchIcon from '../../assets/search.svg';
import './SearchBar.scss';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { getCategories } from '../../api/categories';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidenav from '../SideNav/SideNav';
import { EcomContext } from '../../context/context';
import Home from '../../pages/Home/Home';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function SearchBar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { categories } = useContext(EcomContext);
  const [selectedCategory, setSelectedCategory] = useState ('All Categories');
  const [searchTerm, setSearchTerm] = useState('');
 


  const handleCategoryChange = (category) => {
    setSelectedCategory(category.title);
    const container = document.querySelector('.search-bar-container');
    container.style.width = '';
  };

  const filteredCategories = categories.filter((item) => {
    return (
      item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const onHandleSearch = () => {
    const title = searchTerm.trim().replace(/\s/g, '').toLowerCase();
    navigate(`/filteredsearch?title=${title}`);
  };

  return (
    <div className="search-bar-container">
      {/* <div>
        <Sidenav />
      </div> */}
      <div className="dropdown-container">
        <DropdownButton id="dropdown-basic-button" title={selectedCategory}>
          {categories.map((category, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleCategoryChange(category)}
            >
              {t(category.title)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
      <div className="search-box-container">
        <input
          type="text"
          className="searchbar"
          placeholder={t("What are you looking for?")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <span>
          <button className="search-button">
            <img src={searchIcon} alt="Search" onClick={onHandleSearch} />
          </button>
        </span>
      </div>
      {/* <Home filteredCategories={filteredCategories} /> */}
    </div>
  );
}

export default SearchBar;
