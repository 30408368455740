import { useState, useEffect, createContext } from 'react';
import { getCart } from '../api/cart';
import { getProducts } from '../api/products';
import { login, signInWithSocialMedia } from '../api/login';
import { getBestSeller } from '../api/bestSeller';
import { getCategories } from '../api/categories';
import { toast } from 'react-toastify';
import { useIdleTimer } from 'react-idle-timer';
// import { useNavigate } from "react-router-dom";

export const EcomContext = createContext();

const EcomContextProvider = ({ children }) => {
  const initialLoginState = JSON.parse(sessionStorage.getItem('isLoggin'));
  const [myProducts, setMyProducts] = useState([]);
  const [cartItem, setCartItem] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [isLoggin, setIsLoggin] = useState(initialLoginState || false);
  const [signinDetails, setSignInDetails] = useState();
  const [cartItemProduct, setCartItemProduct] = useState({});
  const [myCategory, setMyCategory] = useState([]);
  const [isFetchCart, setIsFetchCart] = useState(false);
  const [combineData, setCombineData] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);
  const [bestSellersProduct, setBestSellersProduct] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchCategories, setSearchCategories] = useState('');
  const [filteredSearch, setFilteredSearch] = useState([]);
  const [savedTransaction, setSavedTransaction] = useState({});

  // const timeout = 60000 * 7;
  const [isIdle, setIsIdle] = useState(false);
  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);

  const idleTimer = useIdleTimer({
    timeout: 30 * 10 * 1000,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    if (isIdle && sessionStorage.getItem('token')) {
      logoutHandler();
    }
  }, [isIdle]);

  // All categories api
  useEffect(() => {
    const fetchAllCategories = async () => {
      try {
        const response = await getCategories();

        const data = await response.json();

        if (data.type === 'success') {
          setCategories([...data?.category]);
        }
      } catch (error) {
        toast.error(error);
      }
    };

    fetchAllCategories();
  }, []);


  const onHandleSearch = () => {
    let filteredCategoriesList = categories.filter((item) => {
      return (
        item.title &&
        item.title.trim().replace(/\s+/g, ' ').toLowerCase().includes(searchCategories.toLowerCase())
      );
    });
    const queryString = filteredCategoriesList
      .map((item) => `title=${encodeURIComponent(item.title)}`)
      .join('&');
    const url = `/filteredsearch?${queryString}`;
    window.location.href = url;
  };

  useEffect(() => {
    const fetchCartItems = async () => {
      if (isLoggin) {
        try {
          const response = await getCart();
          const data = await response.json();

          if (data.type === 'success') {
            if (data?.cart?.products.length === 0) {
              setCartItem([]);

              setCartItemProduct({ data });
            }

            const cartData = data?.cart?.products;
            setCartItem([...cartData]);

            setCartItemProduct({ data });
          } else if (data.type === 'error') {
            setCartItem([]);

            setCartItemProduct({ data });
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await getProducts();

        const data = await response.json();

        if (data.type === 'success') {
          setMyProducts([...data?.products]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCartItems();

    fetchProducts();
  }, [isFetchCart, isLoggin]);

  useEffect(() => {
    const fetchBestSellers = async () => {
      try {
        const response = await getBestSeller();
        const data = await response.json();
        setBestSellers([...data.data]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBestSellers();
  }, [isFetchCart]);

  useEffect(() => {
    if (myProducts?.length && bestSellers?.length) {
      if (bestSellers?.length === 0) {
        setBestSellersProduct(null);
      }
      let bestSellersProductCombine = bestSellers
        ?.map((bestSeller) => {
          let product = myProducts?.find((p) => p?._id === bestSeller?._id);
          if (!product) {
            return null;
          }
          return {
            ...bestSeller,
            title: product.title,
            price: product.price,
            image: product.image,
          };
        })
        .filter(Boolean);
      setBestSellersProduct([...bestSellersProductCombine]);
    }
  }, [myProducts, isFetchCart]);

  useEffect(() => {
    if (myProducts?.length && cartItem?.length) {
      if (cartItem?.length === 0) {
        setCombineData(null);
      }

      let combinedData = cartItem?.map((cartItem) => {
        let product = myProducts?.find((p) => p._id === cartItem.productId);

        return {
          ...cartItem,
          title: product?.title,
          price: product?.price,
          image: product?.image,
        };
      });

      setCombineData([...combinedData]);
    }
  }, [myProducts, cartItem, isFetchCart, isLoggin]);

  const loginHandler = async (mobile, password) => {
    try {
      const response = await login(mobile, password);
      const data = await response.json();

      if (data.type === 'error') {
        return data;
      }

      if (data.type === 'success') {
        // Set expiration time to 30 minutes from now
        const expiration = new Date().getTime() + 30 * 5 * 1000;

        // Store token and user info in session storage
        sessionStorage.setItem('token', data.accessToken);
        sessionStorage.setItem('userInfo', JSON.stringify(data));
        sessionStorage.setItem('userId', data._id);
        sessionStorage.setItem('expiration', expiration.toString());
        setUserInfo(data);
        setIsLoggin(true);

        // // Start a timer to clear session storage when token expires
        // const timeout = expiration - new Date().getTime();
        // setTimeout(() => {
        //   sessionStorage.removeItem("token");
        //   sessionStorage.removeItem("userInfo");
        //   sessionStorage.removeItem("isLoggin");
        //   sessionStorage.removeItem("expiration");
        //   sessionStorage.removeItem("email");
        //   sessionStorage.removeItem("userId");
        //   setUserInfo({});
        //   setIsLoggin(false);
        // }, timeout);

        // Store isLoggin flag in session storage
        sessionStorage.setItem('isLoggin', true);
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const socialMediaLoginHandler = async (userData) => {
    try {
      const response = await signInWithSocialMedia(userData);
      const data = await response.json();


      if (data.type === 'social/google.com') {
        sessionStorage.setItem('token', data.accessToken);
        sessionStorage.setItem('userInfo', JSON.stringify(data));
        sessionStorage.setItem('email', data.email);
        sessionStorage.setItem('userId', data._id);

        setUserInfo(data);
        setIsLoggin(true);

        // // Start a timer to clear session storage when token expires
        // const timeout = expiration - new Date().getTime();
        // setTimeout(() => {
        //   sessionStorage.removeItem("token");
        //   sessionStorage.removeItem("userInfo");
        //   sessionStorage.removeItem("isLoggin");
        //   sessionStorage.removeItem("expiration");
        //   sessionStorage.removeItem("email");
        //   sessionStorage.removeItem("userId");
        //   setUserInfo({});
        //   setIsLoggin(false);
        // }, timeout);

        // Store isLoggin flag in session storage
        sessionStorage.setItem('isLoggin', true);
        return data;
      }

      if (data.type === 'social/facebook.com') {
        sessionStorage.setItem('token', data.accessToken);
        sessionStorage.setItem('userInfo', JSON.stringify(data));
        sessionStorage.setItem('email', data.email);
        sessionStorage.setItem('userId', data._id);

        setUserInfo(data);
        setIsLoggin(true);

        // // Start a timer to clear session storage when token expires
        // const timeout = expiration - new Date().getTime();
        // setTimeout(() => {
        //   sessionStorage.removeItem("token");
        //   sessionStorage.removeItem("userInfo");
        //   sessionStorage.removeItem("isLoggin");
        //   sessionStorage.removeItem("expiration");
        //   sessionStorage.removeItem("email");
        //   sessionStorage.removeItem("userId");
        //   setUserInfo({});
        //   setIsLoggin(false);
        // }, timeout);

        // Store isLoggin flag in session storage
        sessionStorage.setItem('isLoggin', true);
        return data;
      }

      // if (data.type === "success") {
      //   // Set expiration time to 30 minutes from now
      //   // const expiration = new Date().getTime() + (30 * 5 * 1000);

      //   // Store token and user info in session storage
      //   sessionStorage.setItem("token", data.accessToken);
      //   sessionStorage.setItem("userInfo", JSON.stringify(data));
      //   // sessionStorage.setItem('expiration', expiration.toString());
      //   setUserInfo(data);
      //   setIsLoggin(true);

      // // Start a timer to clear session storage when token expires
      // const timeout = expiration - new Date().getTime();
      // setTimeout(() => {
      //   sessionStorage.removeItem("token");
      //   sessionStorage.removeItem("userInfo");
      //   sessionStorage.removeItem("isLoggin");
      //   sessionStorage.removeItem("expiration");
      //   sessionStorage.removeItem("email");
      //   sessionStorage.removeItem("userId");
      //   setUserInfo({});
      //   setIsLoggin(false);
      // }, timeout);

      // Store isLoggin flag in session storage
      // sessionStorage.setItem("isLoggin", true);
      // return data;
      // }
    } catch (error) {
      console.log(error);
    }
  };
  const logoutHandler = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userInfo');
    sessionStorage.removeItem('isLoggin');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('expiration');
    sessionStorage.removeItem('email');
    setUserInfo({});
    setIsLoggin(false);
  };

  const isLoggedIn = async () => {
    try {
      let userInfo = sessionStorage.getItem('userInfo');
      userInfo = JSON.parse(userInfo);

      if (userInfo) {
        setUserInfo(userInfo);
        const storedIsLoggin = sessionStorage.getItem('isLoggin');
        const parsedIsLoggin = JSON.parse(storedIsLoggin);

        setIsLoggin(parsedIsLoggin || false);

        // // Check if session has expired
        const expiration = sessionStorage.getItem('expiration');
        if (expiration && new Date().getTime() > parseInt(expiration)) {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('adminInfo');
          sessionStorage.removeItem('isLoggin');
          sessionStorage.removeItem('adminId');
          sessionStorage.removeItem('expiration');
          setUserInfo({});
          setIsLoggin(false);
        }
      } else {
        setIsLoggin(false);
        setUserInfo({});
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <EcomContext.Provider
      value={{
        setCombineData,
        combineData,
        bestSellersProduct,
        searchCategories,
        filteredSearch,
        socialMediaLoginHandler,
        onHandleSearch,
        setSearchCategories,
        categories,
        cartItem,
        cartItemProduct,
        myProducts,
        loginHandler,
        logoutHandler,
        isLoggin,
        setIsLoggin,
        userInfo,
        setIsFetchCart,
        isFetchCart,
        setSavedTransaction,
        idleTimer,
      }}
    >
      {children}
    </EcomContext.Provider>
  );
};

export default EcomContextProvider;
