import React, { useContext, useEffect, useState } from 'react';
import './index.css';
// import leafImg from "../../../assets/leaf.jpg";
// import Header from "../../../components/Header/Header";
// import SearchBar from "../../../components/SearchBar/SearchBar";
// import Footer from "../../../components/Footer/Footer";
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';
// import { IMG_URL } from "../../../config";
import imgOne from '../../../assets/jcb.jpg';
import { BASE_URL } from '../../../config';
import { getMyReferrals } from '../../../api/profile';
import { EcomContext } from '../../../context/context';
import { useTranslation } from 'react-i18next';


const Referrals = () => {
  const { t } = useTranslation();
  const {myProducts} = useContext(EcomContext);
  const [referrals, setReferrals] = useState([]);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '584px',
      transform: 'translate(-50%, -50%)',
    },
  };
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await getMyReferrals();
        const data = await response.json();
        if (data.type === 'success') {
          setReferrals(data.referral);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrder();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="my-order-list">
          <div className="card card_myOrderlist">
            <h2 className="card-header">{t('My Referrals')}</h2>
            {true ? (
              <div className="order-list">
                <table class="table">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">{t('Referred Name')}</th>
                        <th scope="col">{t('Referred Mobile')}</th>
                        <th scope="col">{t('Referred Address')}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {/* Assuming `orders` is an object containing order IDs as keys */}
                    {referrals.map((referral, i) => {
                        return (
                            <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>{referral?.['rname']}</td>
                                <td>{referral?.['rmobile']}</td>
                                <td>{referral?.['raddress']}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
              </div>
            ) : (
              <div className="no-order-list">{t('No Referrals')}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referrals;
