import React, { useContext, useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import SearchBar from '../../components/SearchBar/SearchBar';
import Footer from '../../components/Footer/Footer';
import grid from '../../assets/grid.svg';
import list from '../../assets/list.svg';
import './CategoryPage.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IMG_URL } from '../../config';

import 'react-toastify/dist/ReactToastify.css';
import { EcomContext } from '../../context/context';
import Loader from '../Loader/Loader';
import imgOne from '../../assets/jcb.jpg';
const CategoryPage = (props) => {
  const { categoryname } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [view, setView] = useState('grid');

  const [toggle, setToggle] = useState('grid');

  const { myProducts } = useContext(EcomContext);

  let categoryFilteredProduct = [];

  categoryFilteredProduct = myProducts?.filter(
    (category) => category.categories[0] === categoryname
  );

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);
  const toggleGridList = () => {
    if (window.innerWidth > 700) {
      setView(view === 'grid' ? 'list' : 'grid');
      setToggle(!toggle);
    }
  };

  const viewFullProduct = (id) => {
    navigate(`/productpage/${id}`);
  };

  return (
    <div>
      <>
        <Header />

        <SearchBar />
      </>
      {isLoading && <Loader />}

      <div className="container">
        <div className="d-flex align-items-baseline justify-content-between">
          <p className="headerName ps-0">{t('Service List')}</p>
          {window.innerWidth > 768 && (
            <button
              onClick={toggleGridList}
              type="button"
              className="toggleBtn"
            >
              {toggle ? (
                <img src={grid} className="img-fluid gridIcon" alt="grid" />
              ) : (
                <img src={list} className="img-fluid listIcon" alt="grid" />
              )}
            </button>
          )}
        </div>
        <div>
          {categoryFilteredProduct.length === 0 ? (
            <div className="text-center my-3">
              {t('There is No Service available')}
            </div>
          ) : view === 'grid' ? (
            <div>
              <div className="product-grid">
                {categoryFilteredProduct.length === 0 ? (
                  <div className="w-100">
                    {t('There is No Service available')}
                  </div>
                ) : (
                  categoryFilteredProduct.map((product) => (
                    <div className="grid-card" key={product._id}>
                      <div className="grid-img">
                        <img
                          src={`${IMG_URL}/${product.image}`}
                          onClick={() => {
                            setIsLoading(true);
                            setTimeout(() => {
                              setIsLoading(false);
                              viewFullProduct(product._id);
                            }, 300);
                          }}
                          alt="product1"
                        />
                      </div>

                      <div className="grid-card-div">
                        <h3>{t(product.title.substring(0, 15))}</h3>
                        <p className="grid-description">
                          {t(product.description.substring(0, 50))}
                        </p>
                        <p className="grid-amount">₹ {product.price}</p>
                        {/* <button onClick={() => addToCart(product)}>Add to Cart</button> */}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="items">
                {categoryFilteredProduct.length === 0 ? (
                  <div>{t('There is no Service in given category')}</div>
                ) : (
                  categoryFilteredProduct.map((product) => (
                    <div className="product_card mt-3 mb-3 product_list_item">
                      <div className="item" key={product._id}>
                        <div
                          className="item-img-name"
                          onClick={() => viewFullProduct(product._id)}
                        >
                          <div className="d-flex justify-content-center align-item-center">
                            <img
                              src={`${IMG_URL}/${product.image}`}
                              alt="product1"
                            />
                            <div className="product_container">
                              <h2 className="ps-3">{t(product.title)}</h2>
                              <p className="product_description ms-3 mb-3">
                                {t(product.description)}
                              </p>
                              <div className="view ms-3 m-0">
                                <a
                                  href={`/productpage/${product._id}`}
                                  onClick={() => viewFullProduct(product._id)}
                                >
                                 {t('View details')}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pe-3">
                          <h3 className="pb-1 ps-5">₹ {product.price}</h3>
                          {/* <button className="details btn btn-primary" onClick={() => addToCart(product)} >Add to Cart</button> */}
                          {/* <button className="details btn btn-primary" onClick={() => addToCart(product)} >Add to Cart</button> */}
                          {/* <button onClick={() => addToCart(product)}>Add to Cart</button> */}
                          {/* <button className='btn btn-primary' onClick={() => addToCart(product)}>Add to Cart</button> */}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CategoryPage;
