import React, { useEffect, useState } from 'react';
import './index.css';
import profileIcon from '../../assets/profile-user.png'
import cartIcon from '../../assets/shopping-cart.png'
import heartIcon from '../../assets/heart.png'
import Header from '../../components/Header/Header';
import SearchBar from '../../components/SearchBar/SearchBar';
import Footer from '../../components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import { createReferralApi } from '../../api/profile';
import { toast } from 'react-toastify';

const CreateReferrals = () => {
  const { t } = useTranslation();
  const user = sessionStorage.getItem('userInfo')
  
  const initalFormData = {
    "rname": "",
    "referredBy": JSON.parse(user)?.['mobile'],
    "rmobile": "",
    "raddress":""
  }
  const [ formData, setFormData] = useState(initalFormData)
  const [activeTab, setActiveTab] = useState('1');

  const handleOnChange = (e, key) => {
    setFormData({
      ...formData,
      [key]: e.target.value
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createReferralApi(formData);

      const data = response.data;

      if (data.type === 'success') {
        toast.success("Referral created successfully");
        setFormData(initalFormData)
      } else {
        toast.error("An error occurred");
      }
    } catch (error) {
      console.error(error.message);
      toast.error("Failed to create referral: " + error.message);
    }
}

  return (
    <div>
      <Header />
      <SearchBar />
      {
        <div className="container-fluid">
          <div className="my-profile">
            <div className="sidebar">
              <div
                className={`sidebar-tab ${activeTab === '1' ? 'active' : ''}`}
              >
                <span className='profile-icon'><img src={profileIcon} alt="" /></span> {t('ReferAndEarn')}
              </div>
            </div>
            <div className="main-content">
              <form onSubmit={handleSubmit}>
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">{t('Referral Name')}</label>
                  <input value={formData['rname']} onChange={(e) => handleOnChange(e, 'rname')} type="text" class="form-control"  />
                </div>
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">{t('Referral Mobile')}</label>
                  <input value={formData['rmobile']} onChange={(e) => handleOnChange(e, 'rmobile')} type="text" class="form-control" />
                </div>
                <div class="mb-3">
                  <label for="exampleFormControlTextarea1" class="form-label">{t('Referral Address')}</label>
                  <textarea onChange={(e) => handleOnChange(e, 'raddress')} class="form-control" rows="3">{formData['raddress']}</textarea>
                </div>
                <div class="col-auto">
                  <button type="submit" class="btn btn-primary mb-3">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      }
      <div className='container-fluid g-0 mt-5'>
        <Footer />
      </div>
    </div>
  );
};

export default CreateReferrals;
