import { BASE_URL } from '../config';

const userId = sessionStorage.getItem('userId');
let token = null;
// const token = sessionStorage.getItem('token');

const updateToken = () => {
  token = sessionStorage.getItem('token');
};

updateToken(); // Set the initial value of the token

export const getBestSeller = async () => {
  updateToken();
  if (token !== null) {
    return fetch(`${BASE_URL}/orders/getbestsell`, {
      method: 'GET',
      headers: {
        token: `${token}`,
        // Authorization: `ADMIN ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error('Token is null'));
  }
};
// export const getAdminBestSellWeekly = async () => {
//   return fetch(`${BASE_URL}/orders/getbestsell`, {
//     method: "GET",
//     headers: {
//       'token': `${token}`,
//       "Content-Type": "application/json"
//     }
//   })
// }
