import { BASE_URL } from '../config';
import axios from 'axios';

export const createOrder = async (data) => {
  let responseData = {};
  const token = sessionStorage.getItem('token');

  try {
    const orderResponse = await axios.post(`${BASE_URL}/orders`, data, {
      headers: {
        token: `${token}`,
        'Content-Type': 'application/json',
      },
    });
    return orderResponse.data;
  } catch (error) {
    console.log(error);
  } 
};

export const getOrdersAsync = async () => {
  const token = sessionStorage.getItem('token');
  const userId = sessionStorage.getItem('userId');
  if (token !== null) {
    return await fetch(`${BASE_URL}/orders/${userId}`, {
      method: 'GET',
      headers: {
        token: `${token}`,
        'Content-Type': 'application/json',
      },
      // body:JSON.stringify(payload)
    });
  } else {
    return Promise.reject(new Error('Token is null'));
  }
};
